import React, {useState, useEffect} from "react";
import Page from "components/Page/Page";
import { useParams } from "react-router-dom";
import * as debtApi from "api/debt.api";
import * as settingApi from "api/setting.api";
import { 
  TableContainer, 
  Table, 
  TableBody, 
  TableRow, 
  TableCell, 
} from "@material-ui/core";
import { useStyles } from "./styles";
import CustomLink from "components/CustomLink/CustomLink";
import { isInt } from "utils/helper";
import GridContainer from "components/Grid/GridContainer";
import { itemKey } from "utils/helper";
import GridItem from "components/Grid/GridItem";
import moment from "moment/moment";
 
export default function DebtShow(){
  const classes = useStyles();
  const params = useParams();
  const id = params.id;
  const [data, setData] = useState({});
  const [pageColor, setPageColor] = useState("success");

  
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await debtApi.getDebt(id);
    const payload = JSON.parse(response.data);
    delete payload.id;
    const debtDebtAlert = await settingApi.getSetting("loan_debt_alert");
    const debtDebtAlertPayload = JSON.parse(debtDebtAlert.data);
    const debtDebtAlertValue = debtDebtAlertPayload.value;
    const now = moment();

    // Arrange the objects
    const newPayload = {};
    newPayload["title"] = payload.title;
    newPayload["description"] = payload.description;
    newPayload["amount"] = payload.amount + " " + payload.currencyData.name;
    newPayload["payer"] = isInt(payload.payer) ? (
      <CustomLink href={`/admin/customers/show/${payload.payerData.id}`}>
        {payload.payerData.name}
      </CustomLink>
    ) : payload.payer;
    newPayload["date"] = payload.date;
  
    if(moment(payload.due_date, "YYYY-MM-DD h:mm:ss").isBefore(now.add(debtDebtAlertValue, "days"))){
      newPayload["due_date"] = (
        <span style={{color: "red"}}>
          {payload.due_date}
        </span>
      );
      setPageColor("danger");
    } else {
      newPayload["due_date"] = payload.due_date;
    }
    if(payload.files && payload.files.length > 0) {
      newPayload["files"] = payload.files;
      delete payload.files;
    }
    setData(newPayload);
  }

  return (
    <Page head={pageColor} title={data.title} object="debts" id={id} actions={["edit", "delete", "list"]} subtitle="">
      <TableContainer>
          <Table sx={{ minWidth: 500 }}>
           <colgroup>
              <col style={{width:'20%'}}/>
              <col style={{width:'80%'}}/>
            </colgroup>
            <TableBody>
              {Object.keys(data).map((key) => (
                <TableRow key={key}>
                  <TableCell className={classes.detailsKey}>{key.toUpperCase()}</TableCell>
                  <TableCell>
                    {key === "files" && data["files"].length > 0 ? (
                      <GridContainer>
                        {data["files"].map((file) => (
                          <GridItem key={itemKey("")} xs={12} md={4}>
                            <a href={file} target="_blank" rel="noreferrer">
                              <div style={{backgroundImage: `url("${file}")`, backgroundSize: "cover", minHeight: "200px", marginBottom: "15px"}}>
                                <img src={file} alt="file" className={classes.file} style={{display: "none"}} />
                              </div>
                            </a>
                          </GridItem>
                        ))}
                      </GridContainer>
                    ): data[key]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      </TableContainer>
    </Page>
  );
}