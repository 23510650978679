import React, {useState, useEffect} from "react";
import Page from "components/Page/Page";
import { useParams } from "react-router-dom";
import * as actionApi from "api/action.api";
import { TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import inputParams from "./inputParams";
import { useStyles } from "./styles";
import _ from "lodash";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { itemKey } from "utils/helper";
import CustomLink from "components/CustomLink/CustomLink";
import { ActionValue } from "components/UsefulComponents/UsefulComponents";
import { isObject } from "utils/helper";
import { formatMoney } from "utils/helper";

export default function ActionShow(){
  const classes = useStyles();
  const params = useParams();
  const id = params.id;
  const [data, setData] = useState({});
  const [files, setFiles] = useState([]);
  const [headColor, setHeadColor] = useState("success");
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await actionApi.getAction(id);
    const payload = JSON.parse(response.data);
    delete payload.id;
    if(payload.files.length > 0) {
      setFiles(payload.files);
      delete payload.files;
    }
    
    // Arrange the objects
    const newPayload = {};
    newPayload["title"] = payload.title;
    newPayload["account"] = (
      <CustomLink href={"/admin/accounts/show/"+payload['accountData']['id']}>
        {payload.accountData.name}
      </CustomLink>
    );
    newPayload["amount"] = (
      <ActionValue value={formatMoney(payload.amount)} />
    );
    if(isObject(payload.payerData)){
      newPayload["payer"] = (
        <CustomLink href={"/admin/customers/show/"+payload['payerData']['id']}>
          {payload.payerData.name}
        </CustomLink>
      );
    } else {
      newPayload["payer"] = payload.payerData;
    }
    newPayload["category"] = payload.category;
    newPayload["description"] = payload.description;
    newPayload["date"] = payload.date;
    setData(newPayload);

    // Set the head color
    if(payload.type && payload.type === "currency_exchange") {
      setHeadColor("warning");
    } else if (payload.amount < 0) {
      setHeadColor("danger");
    } else {
      setHeadColor("success");
    }
  }

  return (
    <Page head={headColor} title={data.title} object="actions" id={id} actions={["create", "edit", "delete", "list"]} subtitle="">
      <TableContainer>
          <Table sx={{ minWidth: 500 }}>
           <colgroup>
              <col style={{width:'20%'}}/>
              <col style={{width:'80%'}}/>
            </colgroup>
            <TableBody>
              {Object.keys(data).map((key) => (
                <TableRow key={key}>
                  <TableCell key={key} className={classes.detailsKey}>{key.toUpperCase()}</TableCell>
                  <TableCell>{data[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      </TableContainer>
      {files.length > 0 && (
        <h3>Files</h3>
      )}
      <GridContainer>
      {files.length > 0 && (
        files.map((file) => (
          <GridItem key={itemKey("")} xs={12} md={4}>
            <a href={file} target="_blank" rel="noreferrer">
              <div style={{backgroundImage: `url("${file}")`, backgroundSize: "cover", minHeight: "200px", marginBottom: "15px"}}>
                <img src={file} alt="file" className={classes.file} style={{display: "none"}} />
              </div>
            </a>
          </GridItem>
        ))
      )}
      </GridContainer>
    </Page>
  );
}