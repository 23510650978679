import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { itemKey } from 'utils/helper';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
	FormControl,
	InputLabel,
	MenuItem,
	FormHelperText,
	Select,
} from '@material-ui/core';
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomSelect(props) {
  const classes = useStyles();
	const {
		formControlProps,
		labelText,
		id,
		selectProps,
		error,
		success,
		options,
		helperText,
	} = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

	return (
    <FormControl 
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      sx={{m: 1, minWidth: 120}}>
      <InputLabel className={classes.labelRoot + labelClasses} id={id + "-label"}>{labelText}</InputLabel>
      <Select
        labelId={id + "-label"}
        id={id}
        label={labelText}
        {...selectProps}
      >
        {options.map((option) => (
          <MenuItem key={itemKey(option.value)} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

CustomSelect.propTypes = {
  formControlProps: PropTypes.object,
  labelText: PropTypes.string,
  id: PropTypes.any,
  labelProps: PropTypes.object,
  selectProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  helperText: PropTypes.string,
};
