import { get } from '../utils/request';

export const getThisYear = async () => {
    return get('/analytics/actions?type=this_year');
};

export const getThisMonth = async () => {
    return get('/analytics/actions?type=this_month');
}

export const getLastMonth = async () => {
    return get('/analytics/actions?type=last_month');
}

export const getCategories = async () => {
    return get('/analytics/categories?type=this_month');
}