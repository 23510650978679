const inputParams = [
	{
		name: 'title',
		label: 'Name',
		type: 'text',
		value: '',
		required: true,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: 'name',
	},
	{
		name: 'description',
		label: 'Description',
		type: 'textArea',
		value: '',
		required: false,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: 'description',
	},
	{
		name: 'account_id',
		label: 'account',
		type: 'select',
		value: '',
		required: true,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: 'account_id',
	},
	{
		name: 'amount',
		label: 'Amount',
		type: 'number',
		value: '0.00',
		required: true,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: 'amount',
	},
	{
		name: 'payer',	
		label: 'Payer/Payee',
		type: 'textAutoComplete',
		value: '',
		required: false,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: 'payer',
	},
	{
		name: 'category',	
		label: 'Category',
		type: 'textAutoComplete',
		value: '',
		required: false,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: 'category',
	},
	{
		name: 'files',	
		label: 'Files',
		type: 'files',
		value: '',
		required: false,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: 'files',
	},
	{
		name: 'date',
		label: 'Datetime',
		type: 'datetime',
		value: '',
		required: true,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: 'date',
	},
];

export default inputParams;
