import { 
  Dashboard,
  Person,
  LibraryBooks,
  BubbleChart,
  LocationOn,
  Notifications,
  Unarchive,
  Language,
  AccountBalance,
  Group,
  CompareArrows,
  Settings as SettingsIcon,
  AccountBalanceWallet,
  PaymentRounded,
} from "@material-ui/icons";
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";

import Accounts from "views/Accounts";
import Customers from "views/Customers";
import Actions from "views/Actions";
import Settings from "views/Settings/Settings";
import Loans from "views/Loans";
import Debts from "views/Debts";

const dashboardRoutes = [
  {
    path: "/home",
    name: "Home",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/accounts",
    name: "Accounts",
    rtlName: "ملف تعريفي للمستخدم",
    icon: AccountBalance,
    component: Accounts,
    layout: "/admin",
    possibleViews: ["list", "create", "edit", "show"],
  },
  {
    path: "/actions",
    name: "Actions",
    rtlName: "ملف تعريفي للمستخدم",
    icon: CompareArrows,
    component: Actions,
    layout: "/admin",
    possibleViews: ["list", "create", "edit", "show"],
  },
  {
    path: "/customers",
    name: "Customers",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Group,
    component: Customers,
    layout: "/admin",
    possibleViews: ["list", "create", "edit", "show"],
  },
  {
    path: "/loans",
    name: "Loans",
    rtlName: "ملف تعريفي للمستخدم",
    icon: AccountBalanceWallet,
    component: Loans,
    layout: "/admin",
    possibleViews: ["list", "create", "edit", "show"],
  },
  {
    path: "/debts",
    name: "Debts",
    rtlName: "ملف تعريفي للمستخدم",
    icon: PaymentRounded,
    component: Debts,
    layout: "/admin",
    possibleViews: ["list", "create", "edit", "show"],
  },
  {
    path: "/user",
    name: "User Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Settings",
    rtlName: "ملف تعريفي للمستخدم",
    icon: SettingsIcon,
    component: Settings,
    layout: "/admin"
  },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin"
  // },
];

export default dashboardRoutes;
