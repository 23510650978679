import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getToken } from 'auth/token';

import Admin from './Admin';
import { AppProviders } from 'contexts';

const App = () => {
	const { token, loading } = useSelector((state) => state.auth);
	return (
		token || getToken() ? (
			<AppProviders>
				<Admin />
			</AppProviders>
		) : (
			<Redirect push to={{
				pathname: '/login',
			}} />
		)
	);
};

export default App;
