import React from "react";
import ActionCreate from "./Create";
import ActionEdit from "./Edit";
import ActionsList from "./List";
import ActionShow from "./Show";

export default function Actions(view){
    if(view === "show"){
        return <ActionShow />;
    } else if (view === "list"){
        return <ActionsList />;
    } else if (view === "create"){
        return <ActionCreate />;
    } else if (view === "edit"){
        return <ActionEdit />;
    }

    return <></>
}