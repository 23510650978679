import { createSlice } from '@reduxjs/toolkit';
import history from 'utils/history';
import { fetchUserData, login, signOut } from './authThunk';

const initialState = {
	token: null,
	loading: false,
	userData: {},
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: {
		[signOut.fulfilled]: (state, action) => {
			state.loading = false;
			state.userData = {};
			state.token = null;
		},
		[login.pending]: (state, action) => {
			state.loading = true;
		},
		[login.fulfilled]: (state, action) => {
			const { token, user } = action.payload;
			state.token = token;
			state.userData = user;
			state.loading = false;
		},
		[login.rejected]: (state, action) => {
			state.loading = false;
		},
		[fetchUserData.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchUserData.fulfilled]: (state, action) => {
			const { token, user } = action.payload;
			state.token = token;
			state.userData = user;
			state.loading = false;
		},
		[fetchUserData.rejected]: (state, action) => {
			state.loading = false;
			state.userData = {};
			state.token = null;
			history.push('/login');
		},
	},
});

export const {} = authSlice.actions;

export default authSlice.reducer;
