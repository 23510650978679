import { fetchUserData } from 'auth/authThunk';
import { getToken } from 'auth/token';
import React from 'react';
import { Redirect } from 'react-router-dom';
import history from './history';
import store from 'store';
import axios from 'axios';

export const redirect = (path) => {
	return <Redirect to={path} />;
};

export const historyRedirect = (path) => {
	return history.push(path);
}

export const setAuthData = async () => {
	if (getToken()) {
		await store.dispatch(fetchUserData());
		axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
	} else return false;
};

export const isObject = (param) => (param && typeof param === 'object' && !Array.isArray(param));

export const setObjectsOnIndexParams = (payload, inputParams, type="show") => {
	if(type === "show") {
		const newPayload = {};
		Object.keys(payload).forEach((key) => {
      const index = inputParams.findIndex((param) => param.name === key);
      let label = "";
      if(index !== -1) {
        label = inputParams[index].label;
        newPayload[label] = payload[key];
      }

      if(isObject(payload[key])){
        const indexFromInputParams = inputParams.findIndex((param) => param.name === key);

        const to = inputParams[indexFromInputParams]["to"];
        if(Array.isArray(to)){
          let value = "";
          to.forEach((param, index) => {
            value += payload[key][param];
            if(index < to.length - 1) value += " - ";
          });
          newPayload[label] = value;
        } else {
          newPayload[label] = payload[key][inputParams[indexFromInputParams]["to"]];
        }
      } else {
        newPayload[label] = payload[key];
      }
      if(label === "Amount" || label === "Balance") {
        newPayload[label] = formatMoney(newPayload[label]);
      }
		});
		return newPayload;
	} else {
    return payload;
  }
};

export const slugify = str => {
  let newStr = str + "";
  return newStr.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
};

export const itemKey = value => {
  return slugify(value) + Math.floor(Math.random() * (100000) + 1);
}

export const checkVariableType = (variable) => {
  return Object.prototype.toString.call(variable).slice(8, -1);
}

export const parse = (response) => {
  return JSON.parse(response.data);
}

export const isAllResponsesSuccess = (responses, statusCode=200) => {
  return responses.every(response => response.status === statusCode);
};

export const number = (value) => {
  return Number(value);
}; 

export const isInt = (value) => {
  return Number.isInteger(parseInt(value));
}

export const doesExist = (value) => {
  return value !== undefined && value !== null;
}

export const formatMoney = (value) => {
  // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const shortenMoney = (value) => {
  return value.toString().slice(0, -3) + "K";
};

export const isDefined = (value) => {
  return value !== undefined && value !== null && value !== "";
}

export const redColor = (value, maxValue) => {
  const red = 255;
  const blue = 0;
  const maxValueScale = 255 / maxValue;
  let green = parseInt(maxValueScale * value);
  green = 255 - green;
  return `rgb(${red}, ${green}, ${blue})`;
};

export const normalizeCurrency = (value) => {
  return Math.round(value * 10000) / 10000;
}