import React, {useState, useEffect, useContext} from "react";
import * as actionApi from "api/action.api";

import { DialogContext, SnackbarContext } from "contexts";
// core components
import Table from "components/Table/Table.js";
import ObjectActions from "components/ObjectActions/ObjectActions.js";
import CustomLink from "components/CustomLink/CustomLink";

import { useStyles } from "./styles";
import { historyRedirect } from "utils/helper";
import Page from "components/Page/Page";
import { ActionValue } from "components/UsefulComponents/UsefulComponents";
import { isObject } from "utils/helper";
import { formatMoney } from "utils/helper";
import { ArrowForward } from "@material-ui/icons";

export default function ActionsList() {
  const classes = useStyles();

  const [heads, setHeads] = useState([]);
  const [actions, setActions] = useState([]);

  const {showDialog} = useContext(DialogContext);
  const {showSnackbar} = useContext(SnackbarContext);

  const availableHeads = ["title", "amount", "payer", "category", "date", "account_id"];

  const fetchData = async () => {
    const response = await actionApi.getActions();
    const payload = JSON.parse(response.data);

    const keys = Object.keys(payload[0]);
    const index = keys.indexOf("id");
    if (index > -1) {
      keys.splice(index, 1);
    }
    const filteredHeads = keys.filter((key) => availableHeads.includes(key));
    const formattedHeads = filteredHeads.map((head) => {
      if (head === "account_id") {
        head = "account";
      }
      return head.toUpperCase();
    });
    formattedHeads.push("ACTIONS");
    formattedHeads.unshift("TYPE");
    
    setHeads(formattedHeads);

    const values = [];
    await payload.forEach((action) => {
      const value = [];
      const pass = ["id", "account_id", "payer"];
      console.log(action);
      keys.forEach(async (key) => {
        if(key == "account_id") {
          if(action.type && action.type === "currency_exchange") {
            value.push((
              <>
                <CustomLink 
                  styles={{color: "red"}}
                  onClick={() => {
                  historyRedirect("/admin/accounts/show/"+action['accountData']['id']);
                }}>{action["accountData"]["name"]}</CustomLink> <br />
                <span style={{color: "orange"}}>
                  <ArrowForward /> <br />
                </span>
                <CustomLink 
                  styles={{color: "green"}}
                  onClick={() => {
                  historyRedirect("/admin/accounts/show/"+action['toAccountData']['id']);
                }}>{action["toAccountData"]["name"]}</CustomLink>
              </>
            ));
          } else {
            value.push((
              <CustomLink onClick={() => {
                historyRedirect("/admin/accounts/show/"+action['accountData']['id']);
              }}>{action["accountData"]["name"]}</CustomLink>
            ));
          }
        } else if(key === "payer"){
          if(isObject(action["payerData"])){
            value.push((
              <CustomLink onClick={() => {
                historyRedirect("/admin/customers/show/"+action['payerData']['id']);
              }}>{action["payerData"]["name"]}</CustomLink>
            ));
          } else {
            value.push(action["payerData"]);
          }
        }
        if(!pass.includes(key) && availableHeads.indexOf(key) != -1) {
          if(key === "amount"){
            let val = formatMoney(action[key]) + " " + action.accountData.currency.symbol;
            let secondVal = null;
            if(action.type && action.type === "currency_exchange") {
              secondVal = formatMoney(action.to_account_amount) + " " + action.toAccountData.currency.symbol;
            }

            value.push((
              <ActionValue type={action.type} value={val} secondValue={secondVal} />
            ));
          } else {
            value.push(action[key]);
          }
        }
      });
      value.push(
        <div className={classes.actions}>
          <ObjectActions 
            handleDeleteClick={() => handleDeleteClick(action.id)}
            handleViewClick={() => handleViewClick(action.id)}
            handleEditClick={() => handleEditClick(action.id)}
          />
        </div>
      );
      // Add types
      value.unshift(
        <>
          {action.type && action.type === "currency_exchange" ? (
            <span style={{color: "rgb(252,145,11)"}}>Currency Exchange</span>
          ) : action.amount < 0 ? (
            <span style={{color: "rgb(231,62,59)"}}>Expense</span>
          ) : (
            <span style={{color: "rgb(84,172,88)"}}>Income</span>
          )}
        </>
      );
      values.push(value);
    });
    
    setActions(values);
  };

  const handleViewClick = (id) => {
    historyRedirect(`/admin/actions/show/${id}`);
  };

  const handleEditClick = (id) => {
    historyRedirect(`/admin/actions/edit/${id}`);
  };

  const handleDelete = async (id) => {
    const response = await actionApi.deleteAction(id);
    if (response.status == 200) {
      fetchData();
      showSnackbar({
        color: "success",
        message: "Action has been deleted successfully",
      });
    }
  };

  const handleDeleteClick = (id) => {
    showDialog({
      title: "Delete Action",
      message: "Are you sure you want to delete this action?",
      onConfirm: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title="Actions" subtitle="List of all actions" object="actions" actions={["create"]}>
      <Table
        tableHeaderColor="success"
        tableHead={heads}
        tableData={actions}
      />
    </Page>
  );
}
