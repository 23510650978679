import React, {useState} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
	FormControl,
	TextField,
} from '@material-ui/core';
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import DateFnsUtils from '@date-io/date-fns';
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import enLocale from "date-fns/locale/en-US";

const useStyles = makeStyles((theme) => ({
  ...styles,
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function CustomDatetimePicker(props) {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
	const {
		formControlProps,
		inputProps,
	} = props;

	return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      sx={{m: 1, minWidth: 120}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker 
          renderInput={(props) => <TextField {...props} />}
          {...inputProps}
          format="yyyy-M-dd HH:mm"
          style={{marginTop: "16px"}}
           />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

CustomDatetimePicker.propTypes = {
  formControlProps: PropTypes.object,
  labelText: PropTypes.string,
  id: PropTypes.any,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  helperText: PropTypes.string,
};
