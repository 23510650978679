import { request, get, post } from '../utils/request';

export const getDebts = async () => {
    return get('/debt');
};

export const getDebt = async (id) => {
    return get(`/debt/${id}`);
}

export const updateDebt = async (id, data) => {
    return post(`/debt/${id}?_method=PUT`, data);
}

export const createDebt = async (data) => {
    return post('/debt', data);
}

export const deleteDebt = async (id) => {
    return request({
        url: `/debt/${id}`,
        method: 'delete',
    });
}
