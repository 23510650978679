import { request, get, put, post } from '../utils/request';

export const getAccounts = async () => {
    return get('/account');
};

export const getAccount = async (id) => {
    return get(`/account/${id}`);
}

export const updateAccount = async (id, data) => {
    return put(`/account/${id}`, data);
}

export const createAccount = async (data) => {
    return post('/account', data);
}

export const deleteAccount = async (id) => {
    return request({
        url: `/account/${id}`,
        method: 'delete',
    });
}
