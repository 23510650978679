import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import { historyRedirect } from 'utils/helper';

const useStyles = makeStyles(styles);

export default function CustomLink(props) {
  const classes = useStyles();
	const {
		href,
    styles,
	} = props;

	return (
    <a onClick={() => {
      historyRedirect(href);
    }} className={classes.link} {...props} style={{cursor: "pointer", ...styles}}>
      {props.children}
    </a>
  );
}

CustomLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string,
};
