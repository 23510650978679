import React, { useContext, useState, useEffect } from 'react';
import { SnackbarContext } from 'contexts';
import ButtonAtRight from 'components/CustomButtons/ButtonAtRight';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Page from 'components/Page/Page';
import history from 'utils/history';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import dayjs from 'dayjs';

import * as customerApi from 'api/customer.api';
import * as currencyApi from 'api/currency.api';
import * as loanApi from 'api/loan.api';
import CustomAutocomplete from 'components/CustomAutocomplete/CustomAutocomplete';
import CustomFile from 'components/CustomFile/CustomFile';
import CustomDatetimePicker from 'components/CustomDatetimePicker/CustomDatetimePicker';
import { doesExist } from 'utils/helper';

export default function LoanCreate() {
  //* States and contexts
  const { showSnackbar } = useContext(SnackbarContext);
  const [currencies, setCurrencies] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState(1);
  const [date, setDate] = useState(dayjs(new Date()).format("YYYY-MM-DD HH:mm"));
  const [dueDate, setDueDate] = useState(dayjs(new Date()).format("YYYY-MM-DD HH:mm"));
  const [payer, setPayer] = useState('');
  const [payerOptions, setPayerOptions] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);


  //* Fetch data
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const responseCurrencies = await currencyApi.getCurrencies();
    const payloadCurrencies = JSON.parse(responseCurrencies.data);
    const currenciesArr = payloadCurrencies.map((currency) => {
      if(currency.is_default) {
        setCurrency(currency.id);
      }
      return {
        value: currency.id,
        label: currency.name,
      };
    });
    setCurrencies(currenciesArr);

    const responseCustomers = await customerApi.getCustomers();
    const payloadCustomers = JSON.parse(responseCustomers.data);
    const customersArr = payloadCustomers.map((customer) => {
      return {
        title: customer.name,
        id: customer.id,
      };
    });
    setPayerOptions(customersArr);
  };

  //* Handle events
  const handleTitleChange = e => setTitle(e.target.value);
  const handleDescriptionChange = e => setDescription(e.target.value);
  const handleAmountChange = e => setAmount(e.target.value);
  const handleCurrencyChange = e => setCurrency(e.target.value);
  const handleDateChange = e => {
    const date = dayjs(e.target.value).format("YYYY-MM-DD HH:mm");
    setDate(date);
  };
  const handleDueDateChange = e => {
    const date = dayjs(e.target.value).format("YYYY-MM-DD HH:mm");
    setDueDate(date);
  };
  const handlePayerChange = (event, newValue) => {
    if(typeof newValue === 'string') {
      setPayer({
        title: newValue,
      });
    } else if(newValue && newValue.inputValue) {
      setPayer({
        title: newValue.inputValue,
      });
    } else {
      setPayer(newValue);
    }
  };
  const handleUploadedFilesChange = uploaded => {
    setUploadedFiles(uploaded);
  };
	const handleSubmit = async (e) => {
		e.preventDefault();
    const formData = new FormData();
    const data = {
      title: title,
      description: description,
      amount: amount,
      currency_id: currency,
      date: date,
      due_date: dueDate,
    };

    if(!description){
      data.description = "";
    }

    if(!payer) {
      data.payer = "";
    }
    if(doesExist(payer.id)){
      data.payer = payer.id + "";
    } else if (payer.title) {
      data.payer = payer.title + "";
    } else {
      data.payer = "";
    }

    Object.keys(data).forEach(key => {
      formData.append(`${key}`, data[key]);
    });

    if(uploadedFiles.length > 0) {
      uploadedFiles.forEach(file => {
        formData.append('files[]', file);
      });
    }

    const response = await loanApi.createLoan(formData);
    const payload = JSON.parse(response.data);
    if(response.status === 201) {
      showSnackbar({
        color: 'success',
        message: 'Loan created successfully',
      });
      setTimeout(() => {
        history.push(`/admin/loans/show/${payload.id}`);
      }, 3000);
    } else {
      showSnackbar({
        color: 'danger',
        message: 'Something went wrong',
      });
    }
	};

  //* Render
	return (
		<Page title="Create Loan" subtitle="Creating a new loan" actions={["list"]} object="loans">
			<form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} key="title_grid">
            <CustomInput
              labelText="Title"
              id="title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "title",
                onChange: handleTitleChange,
                value: title,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="payer_grid">
            <CustomAutocomplete labelText="Payer/Payee" id="payer"
              options={payerOptions}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "payer",
                value: payer,
                onChange: handlePayerChange,
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="desc_grid">
            <CustomInput
              labelText="Description"
              id="description"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "description",
                onChange: handleDescriptionChange,
                value: description,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="currency_grid">
            <CustomSelect
              labelText="Currency"
              id="currency"
              options={currencies}
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                name: "currency",
                onChange: handleCurrencyChange,
                value: currency,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="amount_grid">
            <CustomInput
              labelText="Amount"
              id="amount"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "amount",
                onChange: handleAmountChange,
                value: amount,
                type: "number",
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="date_grid">
            <CustomDatetimePicker
              labelText="Date"
              id="date"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "date",
                onChange: handleDateChange,
                value: date,
                type: "text",
                required: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="due_date_grid">
          <CustomDatetimePicker
              labelText="Due Date"
              id="due_date"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "due_date",
                onChange: handleDueDateChange,
                value: dueDate,
                type: "text",
                required: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} key="files_grid">
            <h3>Files</h3>
            <CustomFile labelText="Upload files" id="files" 
              formControlProps={{
                fullWidth: true,
              }}
              uploadedFiles={uploadedFiles}
              onChange={handleUploadedFilesChange}
              inputProps={{
                id: "files",
                name: "files",
                required: false,
              }} />
          </GridItem>
        </GridContainer>
				<ButtonAtRight
					color="success"
					type="submit"
					style={{ marginTop: '10px' }}
				>
					Create
				</ButtonAtRight>
			</form>
		</Page>
	);
}
