import React, { useState, createContext } from 'react';
import Dialog from 'components/Dialog/Dialog';

const DialogContext = createContext();

const DialogProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState({});

    const showDialog = (options) => {
        if(options.title) setTitle(options.title);
        if(options.message) setMessage(options.message);
        if(options.onConfirm) {
            setOnConfirm({
                fn: () => {
                    options.onConfirm();
                    setOpen(false);
                },
            });
        } else {
            setOpen(false);
        }
        setOpen(true);
    }

    const closeDialog = () => {
        setOpen(false);
    }

    return (
        <DialogContext.Provider value={{ showDialog, closeDialog }}>
            {children}
            <Dialog open={open} title={title} message={message} cancel={() => setOpen(false)} onConfirm={onConfirm.fn} />
        </DialogContext.Provider>
    );
}

export { DialogContext, DialogProvider };