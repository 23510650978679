import { createAsyncThunk } from '@reduxjs/toolkit';
import {getToken, removeToken, setToken} from './token';
import * as request from 'utils/request';

export const fetchUserData = createAsyncThunk('auth/fetchUserData', async (_, {rejectWithValue}) => {
    try{
        const accessToken = getToken();
        const response = await request.get('/user', accessToken);
        const data = JSON.parse(response.data);

        return {...data, accessToken};
    }catch(e){
        removeToken();
        return rejectWithValue('');
    }
});

export const login = createAsyncThunk('auth/login', async (payload) => {
    const response = await request.post('/login', payload);
    const data = JSON.parse(response.data);
    setToken(data.token);
    return data;
});

export const signOut = createAsyncThunk('auth/signOut', async () => {
    const response = await request.post('/logout');
    removeToken();
});