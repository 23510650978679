import React from "react";
import CustomerCreate from "./Create";
import CustomerEdit from "./Edit";
import CustomersList from "./List";
import CustomerShow from "./Show";

export default function Customers(view){
    if(view === "show"){
        return <CustomerShow />;
    } else if (view === "list"){
        return <CustomersList />;
    } else if (view === "create"){
        return <CustomerCreate />;
    } else if (view === "edit"){
        return <CustomerEdit />;
    }

    return <></>
}