import { makeStyles } from '@material-ui/core/styles';

const styles = {
  buttonOuter: {
    display: 'flex',
    justifyContent: 'end',
  }
};

export const useStyles = () => {
	return makeStyles(styles)();
};
