import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
	FormControl,
	FormHelperText,
  TextField,
} from '@material-ui/core';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

const filter = createFilterOptions();

export default function CustomAutocomplete(props) {
  const classes = useStyles();
	const {
		formControlProps,
		labelText,
		id,
		inputProps,
		options,
		helperText,
	} = props;

	return (
    <FormControl 
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      sx={{m: 1, minWidth: 120}}>
      
      <Autocomplete
        freeSolo
        selectOnFocus
        id={id}
        label={labelText}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}  
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }

          if(option.title){
            return option.title;
          }
          // Regular option
          return "";
        }}
        renderOption={(option) => option.title}
        renderInput={(params) => (
          <TextField {...params} label={labelText} />
        )}
        {...inputProps}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

CustomAutocomplete.propTypes = {
  formControlProps: PropTypes.object,
  labelText: PropTypes.string,
  id: PropTypes.any,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  helperText: PropTypes.string,
};
