import React, {useState, useEffect, useContext} from "react";
import Page from "components/Page/Page";
import { useParams } from "react-router-dom";
import { DialogContext, SnackbarContext } from "contexts";
import { useStyles } from "./styles";
import inputParams from "./inputParams";
import CustomSelect from "components/CustomSelect/CustomSelect";

import * as accountApi from 'api/account.api';
import * as currencyApi from 'api/currency.api';
import * as accountGroupApi from 'api/accountGroup.api';
import * as actionApi from 'api/action.api';
import * as customerApi from 'api/customer.api';
import CustomTextarea from 'components/CustomTextarea/CustomTextarea';
import CustomAutocomplete from 'components/CustomAutocomplete/CustomAutocomplete';
import dayjs from 'dayjs';
import CustomDatetimePicker from 'components/CustomDatetimePicker/CustomDatetimePicker';
import CustomFile from 'components/CustomFile/CustomFile';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import ButtonAtRight from 'components/CustomButtons/ButtonAtRight';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { historyRedirect } from "utils/helper";
import { parse } from "utils/helper";
import { getImage } from "api/image.api";
import moment from "moment";
import { isObject } from "utils/helper";
import { doesExist } from "utils/helper";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

export default function ActionEdit(){
  const params = useParams();

  const {showSnackbar} = useContext(SnackbarContext);
  const [currencies, setCurrencies] = useState([]);
  const [accountGroups, setAccountGroups] = useState([]);

  const [actionTitle, setActionTitle] = useState('');
  const [actionDescription, setActionDescription] = useState('');
  const [actionAmount, setActionAmount] = useState('');
  const [account, setAccount] = useState(1);
  const [accountOptions, setAccountOptions] = useState([]);
  const [payer, setPayer] = useState("");
  const [payerOptions, setPayerOptions] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [datetime, setDatetime] = useState(dayjs(new Date()).format('YYYY-MM-DD HH:mm'));
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [actionType, setActionType] = useState("Expense");  // 0 - expense, 1 - income
  const [pageType, setPageType] = useState("success");
  const [isFilesFetched, setIsFilesFetched] = useState(false);
  const [showInAnalytics, setShowInAnalytics] = useState(true);

  const handleActionTitleChange = e => setActionTitle(e.target.value);
  const handleActionDescriptionChange = e => setActionDescription(e.target.value);
  const handleActionAmountChange = e => setActionAmount(e.target.value);
  const handleAccountChange = e => setAccount(e.target.value);
  const handleActionTypeChange = e => {
    setActionType(e.target.value);
  };
  const handlePayerChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setPayer({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setPayer({
        title: newValue.inputValue,
      });
    } else {
      setPayer(newValue);
    }
  };
  const handleCategoryChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setCategory({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      setCategory({
        title: newValue.inputValue,
      });
    } else {
      setCategory(newValue);
    }
  };
  const handleDateTimeChange = e => {
    const datetime = dayjs(e).format('YYYY-MM-DD HH:mm');
    setDatetime(datetime);
  };
  const handleUploadedFilesChange = uploaded => {
    setUploadedFiles(uploaded);
  };
  const handleShowInAnalyticsChange = e => {
    setShowInAnalytics(e.target.checked);
  };
  
  
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setPageType(actionType === "Expense" ? "danger" : "success");
  }, [actionType])

  const fetchData = async () => {    
    const responseAccounts = await accountApi.getAccounts();
    const payloadAccounts = JSON.parse(responseAccounts.data);
    const accountsArr = payloadAccounts.map((account) => {
      return {
        value: account.id,
        label: `${account.name}`,
      };
    });
    setAccountOptions(accountsArr);

    const responseCurrencies = await currencyApi.getCurrencies();
    const payloadCurrencies = JSON.parse(responseCurrencies.data);
    const currenciesArr = payloadCurrencies.map((currency) => {
      return {
        value: currency.id,
        label: currency.name,
      };
    });
    setCurrencies(currenciesArr);

    const responseAccountGroups = await accountGroupApi.getAccountGroups();
    const payloadAccountGroups = JSON.parse(responseAccountGroups.data);
    const accountGroupsArr = payloadAccountGroups.map((accountGroup) => {
      return {
        value: accountGroup.id,
        label: `${accountGroup.name} (${accountGroup.currency.symbol})`,
      };
    });
    setAccountGroups(accountGroupsArr);

    const responseCustomers = await customerApi.getCustomers();
    const payloadCustomers = JSON.parse(responseCustomers.data);
    const customersArr = payloadCustomers.map((customer) => {
      return {
        title: customer.name,
        id: customer.id,
      };
    });
    setPayerOptions(customersArr);

    const responseCategories = await actionApi.getCategories();
    const payloadCategories = JSON.parse(responseCategories.data);
    if(payloadCategories.length > 0) {
      const categoriesArr = payloadCategories.map((category) => {
        return {
          title: category,
        };
      });
      setCategoryOptions(categoriesArr);
    }


    const action = parse(await actionApi.getAction(params.id));
    let payer = "";
    if(isObject(action.payerData)) {
      payer = action.payerData.name;
    } else {
      payer = action.payer;
    }
    setActionTitle(action.title);
    setActionDescription(action.description);
    setActionAmount(Math.abs(action.amount));
    setAccount(action.accountData.id);
    setPayer(payer);
    setCategory({title: action.category});
    setDatetime(moment(action.date).format('YYYY-MM-DD HH:mm'));
    setActionType(action.amount < 0 ? "Expense" : "Income");
    setShowInAnalytics(action.show_in_analytics == 1 ? true : false);

    const allFiles = [];
    if(action.files.length > 0) {
      for (let i = 0; i < action.files.length; i++) {
        const fileURL = action.files[i];
        const fileName = fileURL.split('/').pop();
        getImage(fileName).then((file) => {
          let fileData = 'data:image/jpeg;base64,' + JSON.parse(file.data);
          allFiles.push(fileData);
          if(allFiles.length === action.files.length) {
            setUploadedFiles(allFiles);
            setIsFilesFetched(true);
          }
        });
      }
    } else {
      setIsFilesFetched(true);
    }
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
    const formData = new FormData();
    const data = {
      title: actionTitle,
      description: actionDescription,
      account_id: account,
      category: category.title,
      date: datetime,
      show_in_analytics: showInAnalytics ? 1 : 0,
    };

    if(actionType === "Expense") {
      data.amount = -1 * actionAmount;
    } else if (actionType === "Income") {
      data.amount = actionAmount;
    }

    if(!payer){
      data.payer = "";
    }

    if(!actionDescription){
      data.description = "";
    }

    if(doesExist(payer.id)) {
      data.payer = payer.id + "";
    } else if(payer.title) {
      data.payer = payer.title + "";
    } else {
      data.payer = "";
    }

    Object.keys(data).forEach(key => {
      formData.append(`${key}`, data[key]);
    });

    
    if(uploadedFiles.length > 0) {
      uploadedFiles.forEach(file => {
        formData.append('files[]', file);
      });
    }
    
    const response = await actionApi.updateAction(params.id, formData);
    const payload = JSON.parse(response.data);
    if (response.status == 200) {
      showSnackbar({
        color: 'success',
        message: `Action has been updated successfully. You're being redirected to this action.`,
      });
      setTimeout(() => {
        historyRedirect(`/admin/actions/show/${payload.id}`);
      }, 3000);
    }
	};

	const inputPerLine = 4;
	const columnSize = 12 / inputPerLine;

	const inputsDivided = [];
	for (let i = 0; i < inputParams.length; i += inputPerLine) {
		inputsDivided.push(inputParams.slice(i, i + inputPerLine));
	}

  return (
    <Page title={actionTitle} head={pageType} subtitle={actionTitle + " is being edited"} object="actions" id={params.id} actions={["view", "delete", "list"]}>
      <form onSubmit={handleSubmit}>
				<GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomRadio labelText="Action Type" id="actionType"
              value={actionType}
              formControlProps={{
                fullWidth: true,
              }}
              options={["Expense", "Income"]}
              inputProps={{
                onChange: handleActionTypeChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="action_title_grid">
            <CustomInput labelText="Action title" id="action_title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "action_title",
                value: actionTitle,
                onChange: handleActionTitleChange,
                type: "text",
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="amount_grid">
            <CustomInput labelText="Amount" id="amount"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "amount",
                value: actionAmount,
                onChange: handleActionAmountChange,
                type: "number",
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="account_grid">
            <CustomSelect labelText="Account" id="account"
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                name: "account",
                value: account,
                onChange: handleAccountChange,
                required: true,
              }}
              options={accountOptions} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="datetime_grid">
            <CustomDatetimePicker labelText="Date&Time" id="datetime"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: datetime,
                onChange: handleDateTimeChange,
                type: "text",
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="payer_grid">
            <CustomAutocomplete labelText="Payer/Payee" id="payer"
              options={payerOptions}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "payer",
                value: payer,
                onChange: handlePayerChange,
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="category_grid">
            <CustomAutocomplete labelText="Category" id="category"
              options={categoryOptions}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "payer",
                value: category,
                onChange: handleCategoryChange,
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} md={9} key="description_grid">
            <CustomTextarea labelText="Description" id="description"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "description",
                value: actionDescription,
                onChange: handleActionDescriptionChange,
                required: false,
              }} />
          </GridItem>
          <GridItem xs={12} md={3} key="action_show_in_analytics_grid"
            style={{display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
            <FormGroup>
              <FormControlLabel control={(
                <Checkbox labelText="Show in analytics" id="action_show_in_analytics" checked={showInAnalytics} onChange={handleShowInAnalyticsChange} />
              )}
              label="Show in analytics" />
            </FormGroup>
          </GridItem>
          <GridItem xs={12} md={12} key="file_grid">
            <h3>Files</h3>
            {isFilesFetched &&
              <CustomFile labelText="Files" id="files"
                formControlProps={{
                  fullWidth: true,
                }}
                uploadedFiles={uploadedFiles}
                onChange={handleUploadedFilesChange}
                initialFiles={uploadedFiles}
                inputProps={{
                  id: "files",
                  name: "files",
                  required: false,
              }} />
            }
          </GridItem>
        </GridContainer>
				<ButtonAtRight color="success" type="submit" style={{ marginTop: '10px' }}>Update</ButtonAtRight>
			</form>
    </Page>
  );
}