import React, {useState, useEffect, useContext} from "react";
import Page from "components/Page/Page";
import { useParams } from "react-router-dom";
import * as customerApi from "api/customer.api";
import { DialogContext, SnackbarContext } from "contexts";
import { TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useStyles } from "./styles";
import ButtonAtRight from "components/CustomButtons/ButtonAtRight";

export default function CustomerShow(){
  const classes = useStyles();
  
  const {showSnackbar} = useContext(SnackbarContext);

  const params = useParams();
  const id = params.id;
  const [inputs, setInputs] = useState({});

  const updateInput = (e) => {
    e.persist();
    setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await customerApi.getCustomer(id);
    const payload = JSON.parse(response.data);
    delete payload.id;
    setInputs(payload);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await customerApi.updateCustomer(id, inputs);
    if(response.status === 200){
      const payload = JSON.parse(response.data);
      showSnackbar({
        color: "success",
        message: `Customer ${payload.name} updated successfully`
      });
    } else {
      showSnackbar({ 
        color: "error", 
        message: "Error updating customer"
      });
    }
  }

  return (
    <Page title={inputs.name} subtitle={inputs.name + " is being edited"} object="customers" id={id} actions={["view", "delete", "list"]}>
      <TableContainer>
        <form onSubmit={handleSubmit}>
          <Table sx={{ minWidth: 500 }}>
            <colgroup>
              <col style={{width:'20%'}}/>
              <col style={{width:'80%'}}/>
            </colgroup>
            <TableBody>
              {Object.keys(inputs).map((key) => (
                <TableRow key={key}>
                  <TableCell className={classes.detailsKey}>{key.toUpperCase()}</TableCell>
                  <TableCell>
                    <CustomInput
                      labelText={key}
                      id={key}
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: key,
                        value: inputs[key],
                        onChange: updateInput
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
              
            </TableBody>
          </Table>
          <ButtonAtRight color="success" type="submit" style={{marginTop: "10px"}}>
            Update
          </ButtonAtRight>
        </form>
      </TableContainer>
    </Page>
  );
}