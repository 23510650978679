const inputParams = [
	{
		name: 'name',
		label: 'Name',
		type: 'text',
		value: '',
		required: true,
	},
	{
		name: 'email',
		label: 'Email',
		type: 'email',
		value: '',
		required: false,
	},
	{
		name: 'phone',
		label: 'Phone',
		type: 'text',
		value: '',
		required: false,
	},
	{
		name: 'address',
		label: 'Address',
		type: 'text',
		value: '',
		required: false,
	},
	{
		name: 'city',
		label: 'City',
		type: 'text',
		value: '',
		required: false,
	},
	{
		name: 'state',
		label: 'State',
		type: 'text',
		value: '',
		required: false,
	},
	{
		name: 'zip',
		label: 'Zip',
		type: 'text',
		value: '',
		required: false,
	},
	{
		name: 'country',
		label: 'Country',
		type: 'text',
		value: '',
		required: false,
	},
	{
		name: 'website',
		label: 'Website',
		type: 'text',
		value: '',
		required: false,
	},
	{
		name: 'notes',
		label: 'Notes',
		type: 'text',
		value: '',
		required: false,
	},
];

export default inputParams;
