import React, { useState, } from 'react';
import { Redirect } from "react-router-dom";
import { getToken } from 'auth/token';
import {login} from 'auth/authThunk';
import { useSelector, useDispatch } from 'react-redux';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import CustomInputPassword from 'components/CustomInputPassword/CustomInputPassword.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardAvatar from 'components/Card/CardAvatar.js';
import CardBody from 'components/Card/CardBody.js';
import { useStyles } from './styles';

import logo from 'assets/img/logo.png';
import { setAuthData } from 'utils/helper';
import { historyRedirect } from 'utils/helper';

export default function Login() {
  const classes = useStyles();
  
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const handleUsernameChange = (e) => setUsername(e.target.value);
	const handlePasswordChange = (e) => setPassword(e.target.value);

	const dispatch = useDispatch();
  const token = getToken();
  
  if(token){
    return (
      <Redirect push to={{
				pathname: '/',
			}} />
    );
  }

	const handleSubmit = async (e) => {
		e.preventDefault();
		const userData = {
			username: username,
			password: password,
		};
    await dispatch(login(userData));
    await setAuthData();
    historyRedirect('/');
	};

	return (
    <GridContainer
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <GridItem xs={12} sm={12} md={4} className={classes.mainItem}>
        <Card profile className={classes.mainCard}>
          <CardAvatar profile>
            <a href="#" onClick={(e) => e.preventDefault()}>
              <img src={logo} alt="..." />
            </a>
          </CardAvatar>
          <h3>Login</h3>
          <CardBody profile>
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <CustomInput
                    labelText="Username"
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={username}
                    inputProps={{
                      onChange: handleUsernameChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <CustomInputPassword
                    labelText="Password"
                    id="password"
                    type="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={password}
                    inputProps={{
                      onChange: handlePasswordChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button color="primary" round type="submit">
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
	);
}
