import React, { useContext, useState, useEffect } from 'react';
import * as accountApi from 'api/account.api';
import { DialogContext, SnackbarContext } from 'contexts';
import ButtonAtRight from 'components/CustomButtons/ButtonAtRight';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Page from 'components/Page/Page';
import inputParams from './inputParams';
import history from 'utils/history';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import * as currencyApi from 'api/currency.api';
import * as accountGroupApi from 'api/accountGroup.api';
import * as actionApi from 'api/action.api';
import * as customerApi from 'api/customer.api';
import CustomTextarea from 'components/CustomTextarea/CustomTextarea';
import CustomAutocomplete from 'components/CustomAutocomplete/CustomAutocomplete';
import dayjs from 'dayjs';
import CustomDatetimePicker from 'components/CustomDatetimePicker/CustomDatetimePicker';
import CustomFile from 'components/CustomFile/CustomFile';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import { doesExist } from 'utils/helper';
import { useParams } from "react-router-dom";
import { isDefined } from 'utils/helper';
import { normalizeCurrency } from 'utils/helper';
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';

export default function ActionCreate() {
  const params = useParams();

	const { showDialog } = useContext(DialogContext);
	const { showSnackbar } = useContext(SnackbarContext);
  const [currencies, setCurrencies] = useState([]);
  const [accountGroups, setAccountGroups] = useState([]);
  const [actionTitle, setActionTitle] = useState('');
  const [actionDescription, setActionDescription] = useState('');
  const [actionAmount, setActionAmount] = useState('');
  const [account, setAccount] = useState(1);
  const [accountOptions, setAccountOptions] = useState([]);
  const [payer, setPayer] = useState("");
  const [payerOptions, setPayerOptions] = useState([]);
  const [category, setCategory] = useState(1);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [datetime, setDatetime] = useState(dayjs(new Date()).format('YYYY-MM-DD HH:mm'));
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [actionType, setActionType] = useState("Expense");  // 0 - expense, 1 - income, 2 - currency exchange
  const [actionPageHead, setActionPageHead] = useState("danger");
  const [toAccount, setToAccount] = useState(1);
  const [currencyRate, setCurrencyRate] = useState(1);
  const [amountToAccountCurrencyEquivalency, setAmountToAccountCurrencyEquivalency] = useState('');
  const [pageSubtitle, setPageSubtitle] = useState(<>Creating an <i><b>Expense</b></i> action</>);
  const [fromAccountCurrency, setFromAccountCurrency] = useState("TRY");
  const [toAccountCurrency, setToAccountCurrency] = useState("USD");
  const [showInAnalytics, setShowInAnalytics] = useState(true);

  const handleActionTitleChange = e => setActionTitle(e.target.value);
  const handleActionDescriptionChange = e => setActionDescription(e.target.value);
  const handleActionAmountChange = async (e) => { 
    setActionAmount(e.target.value);
  };
  const handleAccountChange = async (e) => {
    setAccount(e.target.value);
    let account = await accountApi.getAccount(e.target.value);
    account = JSON.parse(account.data);
    setFromAccountCurrency(account.currency.code);
  };
  const handleToAccountChange = async (e) => {
    setToAccount(e.target.value);
    let account = await accountApi.getAccount(e.target.value);
    account = JSON.parse(account.data);
    setToAccountCurrency(account.currency.code);
  };
  const handleCurrencyRateChange = e => () => setCurrencyRate(e.target.value);
  const handleCalculate = async () => {
    const res = await currencyApi.convert(fromAccountCurrency, toAccountCurrency);
    const data = await JSON.parse(res.data);
    const value = data;
    setCurrencyRate(normalizeCurrency(value));
    let amount = actionAmount;
    if (amount === "") {
      amount = 1;
    }
    setAmountToAccountCurrencyEquivalency(normalizeCurrency(amount * value));
  };
  const handleAmountToAccountCurrencyEquivalency = e => setAmountToAccountCurrencyEquivalency(e.target.value);
  
  useEffect(() => {
    handleCalculate();
  }, [actionAmount, fromAccountCurrency, toAccountCurrency]);

  const handleActionTypeChange = e => {
    setActionType(e.target.value);
    if (e.target.value === "Expense") {
      setActionPageHead("danger");
      setPageSubtitle(<>Creating an <i><b>Expense</b></i> action</>);
    } else if (e.target.value === "Income") {
      setActionPageHead("success");
      setPageSubtitle(<>Creating an <i><b>Income</b></i> action</>);
    } else {
      setActionPageHead("warning");
      setPageSubtitle(<>Creating a <i><b>Currency Exchange</b></i> action</>);
    }
  };
  const handlePayerChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setPayer({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setPayer({
        title: newValue.inputValue,
      });
    } else {
      setPayer(newValue);
    }
  };
  const handleCategoryChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setCategory({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      setCategory({
        title: newValue.inputValue,
      });
    } else {
      setCategory(newValue);
    }
  };
  const handleDateTimeChange = e => {
    const datetime = dayjs(e).format('YYYY-MM-DD HH:mm');
    setDatetime(datetime);
  };
  const handleUploadedFilesChange = uploaded => {
    setUploadedFiles(uploaded);
  };
  const handleShowInAnalyticsChange = e => {
    setShowInAnalytics(e.target.checked);
  };

  useEffect(() => {
    fetchData();
    if(isDefined(params["account"])) {
      setAccount(params["account"]);
    }
  }, []);

  const fetchData = async () => {
    const responseAccounts = await accountApi.getAccounts();
    const payloadAccounts = JSON.parse(responseAccounts.data);
    const accountsArr = payloadAccounts.map((account) => {
      return {
        value: account.id,
        label: `${account.name}`,
      };
    });
    setAccountOptions(accountsArr);

    const responseCurrencies = await currencyApi.getCurrencies();
    const payloadCurrencies = JSON.parse(responseCurrencies.data);
    const currenciesArr = payloadCurrencies.map((currency) => {
      return {
        value: currency.id,
        label: currency.name,
      };
    });
    setCurrencies(currenciesArr);

    setToAccount(2);
    await handleCalculate();
    
    // const toAccountParam = payloadAccounts.findIndex(account => account.id === toAccount);
    // const toAccountCurrencyIndex = payloadCurrencies.findIndex(currency => currency.id === payloadAccounts[toAccountParam].currency.id);
    // setCurrencyRate(payloadCurrencies[toAccountCurrencyIndex].rate);
    

    const responseAccountGroups = await accountGroupApi.getAccountGroups();
    const payloadAccountGroups = JSON.parse(responseAccountGroups.data);
    const accountGroupsArr = payloadAccountGroups.map((accountGroup) => {
      return {
        value: accountGroup.id,
        label: `${accountGroup.name} (${accountGroup.currency.symbol})`,
      };
    });
    setAccountGroups(accountGroupsArr);

    const responseCustomers = await customerApi.getCustomers();
    const payloadCustomers = JSON.parse(responseCustomers.data);
    const customersArr = payloadCustomers.map((customer) => {
      return {
        title: customer.name,
        id: customer.id,
      };
    });
    setPayerOptions(customersArr);

    const responseCategories = await actionApi.getCategories();
    const payloadCategories = JSON.parse(responseCategories.data);
    if(payloadCategories.length > 0) {
      const categoriesArr = payloadCategories.map((category) => {
        return {
          title: category,
        };
      });
      setCategoryOptions(categoriesArr);
    }
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
    const formData = new FormData();
    const data = {
      title: actionTitle,
      description: actionDescription,
      account_id: account,
      category: category.title,
      date: datetime,
      show_in_analytics: showInAnalytics ? 1 : 0,
    };

    if(actionType === "Expense") {
      data.amount = -1 * actionAmount;
    } else if (actionType === "Income") {
      data.amount = actionAmount;
    } else if (actionType === "Currency Exchange") {
      data.amount = actionAmount;
      data.to_account_id = toAccount;
      data.currency_rate = currencyRate;
      data.to_account_amount = amountToAccountCurrencyEquivalency;
      data.type = "currency_exchange";
      data.show_in_analytics = 0;
    }

    if(!payer){
      data.payer = "";
    }

    if(!actionDescription){
      data.description = "";
    }

    if(doesExist(payer.id)){
      data.payer = payer.id + "";
    } else if(payer.title) {
      data.payer = payer.title + "";
    } else {
      data.payer = "";
    }

    Object.keys(data).forEach(key => {
      formData.append(`${key}`, data[key]);
    });

    if(uploadedFiles.length > 0) {
      uploadedFiles.forEach(file => {
        formData.append('files[]', file);
      });
    }
    
    const response = await actionApi.createAction(formData);
    const payload = JSON.parse(response.data);
    if (response.status == 201) {
      showSnackbar({
        color: 'success',
        message: `Action has been created successfully. You're being redirected to the actions page.`,
      });
      setTimeout(() => {
        history.push(`/admin/actions/show/${payload.id}`);
      }, 3000);
    }
	};

	const inputPerLine = 4;
	const columnSize = 12 / inputPerLine;

	const inputsDivided = [];
	for (let i = 0; i < inputParams.length; i += inputPerLine) {
		inputsDivided.push(inputParams.slice(i, i + inputPerLine));
	}

	return (
		<Page head={actionPageHead} title="Create Action" subtitle={pageSubtitle} actions={["list"]} object="actions">
			<form onSubmit={handleSubmit}>
				<GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomRadio labelText="Action Type" id="actionType"
              value={actionType}
              formControlProps={{
                fullWidth: true,
              }}
              options={["Expense", "Income", "Currency Exchange"]}
              inputProps={{
                onChange: handleActionTypeChange,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="action_title_grid">
            <CustomInput labelText="Action title" id="action_title"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "action_title",
                value: actionTitle,
                onChange: handleActionTitleChange,
                type: "text",
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} key="amount_grid">
            <CustomInput labelText="Amount" id="amount"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "amount",
                value: actionAmount,
                onChange: handleActionAmountChange,
                type: "number",
                required: true,
              }} />
          </GridItem>
          {actionType !== "Currency Exchange" ? (
          <>
          <GridItem xs={12} sm={12} md={3} key="account_grid">
            <CustomSelect labelText="Account" id="account"
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                name: "account",
                value: account,
                onChange: handleAccountChange,
                required: true,
              }}
              options={accountOptions} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="datetime_grid">
            <CustomDatetimePicker labelText="Date&Time" id="datetime"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: datetime,
                onChange: handleDateTimeChange,
                type: "text",
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="payer_grid">
            <CustomAutocomplete labelText="Payer/Payee" id="payer"
              options={payerOptions}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "payer",
                value: payer,
                onChange: handlePayerChange,
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="category_grid">
            <CustomAutocomplete labelText="Category" id="category"
              options={categoryOptions}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "category",
                value: category,
                onChange: handleCategoryChange,
                required: true,
              }} />
          </GridItem>
          </>
          ): (
          <>
          <GridItem xs={12} sm={12} md={3} key="from_account_grid">
            <CustomSelect labelText="From Account" id="from_account"
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                name: "account",
                value: account,
                onChange: handleAccountChange,
                required: true,
              }}
              options={accountOptions} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="to_account_grid">
            <CustomSelect labelText="To Account" id="to_account"
              formControlProps={{
                fullWidth: true,
              }}
              selectProps={{
                name: "to_account",
                value: toAccount,
                onChange: handleToAccountChange,
                required: true,
              }}
              options={accountOptions} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="amountToAccountCurrencyEquivalency">
            <CustomInput labelText="To Account Currency Equivalency" id="amountToAccountCurrencyEquivalency"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "amountToAccountCurrencyEquivalency",
                value: amountToAccountCurrencyEquivalency,
                onChange: handleAmountToAccountCurrencyEquivalency,
                type: "number",
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="to_account_currency_rate_grid">
            <CustomInput labelText="To Account Currency Rate" id="to_account_currency_rate"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "to_account_currency_rate",
                value: currencyRate,
                onChange: handleCurrencyRateChange,
                type: "number",
                required: true,
              }} />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} key="datetime_grid">
            <CustomDatetimePicker labelText="Date&Time" id="datetime"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: datetime,
                onChange: handleDateTimeChange,
                type: "text",
                required: true,
              }} />
          </GridItem>
          </>
          )}
          <GridItem xs={12} md={actionType !== "Currency Exchange" ? 9 : 9} key="description_grid">
            <CustomTextarea labelText="Description" id="description"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                name: "description",
                value: actionDescription,
                onChange: handleActionDescriptionChange,
                required: false,
              }} />
          </GridItem>
          {(actionType !== "Currency Exchange") && (
            <GridItem xs={12} md={3} key="action_show_in_analytics_grid"
              style={{display: "flex", alignItems: "flex-end", justifyContent: "center"}}>
              <FormGroup>
                <FormControlLabel control={(
                  <Checkbox labelText="Show in analytics" id="action_show_in_analytics" checked={showInAnalytics} onChange={handleShowInAnalyticsChange} />
                )}
                label="Show in analytics" />
              </FormGroup>
            </GridItem>
          )}
          <GridItem xs={12} md={12} key="file_grid">
            <h3>Files</h3>
            <CustomFile labelText="Files" id="files"
              formControlProps={{
                fullWidth: true,
              }}
              uploadedFiles={uploadedFiles}
              onChange={handleUploadedFilesChange}
              inputProps={{
                id: "files",
                name: "files",
                required: false,
              }} />
          </GridItem>
        </GridContainer>
				<ButtonAtRight color="success" type="submit" style={{ marginTop: '10px' }}>Create</ButtonAtRight>
			</form>
		</Page>
	);
}
