import React from 'react';
import PropTypes from 'prop-types';
import { itemKey } from 'utils/helper';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import {
	FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from '@material-ui/core';
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomTextarea(props) {
  const classes = useStyles();
	const {
		formControlProps,
		labelText,
		inputProps,
		options,
    value,
	} = props;

	return (
    <FormControl 
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      sx={{m: 1, minWidth: 120}}>
      <FormLabel style={{textAlign: "center"}} id="demo-row-radio-buttons-group-label">{labelText}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value}
        {...inputProps}
        style={{justifyContent: "center"}}
      >
        {options.map((option, index) => (
          <FormControlLabel 
            key={itemKey(option)} 
            value={option}
            control={<Radio />} 
            label={option} />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

CustomTextarea.propTypes = {
  formControlProps: PropTypes.object,
  labelText: PropTypes.string,
  id: PropTypes.any,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  helperText: PropTypes.string,
};
