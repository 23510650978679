import React, {useState, useEffect, useContext} from "react";
import * as customerApi from "api/customer.api";

import { DialogContext, SnackbarContext } from "contexts";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import ObjectActions from "components/ObjectActions/ObjectActions.js";

import { useStyles } from "./styles";
import { Redirect, useParams } from "react-router-dom";
import history from "utils/history";
import { redirect } from "utils/helper";
import { historyRedirect } from "utils/helper";
import Page from "components/Page/Page";

export default function CustomersList() {
  const classes = useStyles();
  const params = useParams();

  const [heads, setHeads] = useState([]);
  const [customers, setCustomers] = useState([]);

  const {showDialog} = useContext(DialogContext);
  const {showSnackbar} = useContext(SnackbarContext);

  const availableHeads = ["name", "email", "phone"];

  const fetchData = async () => {
    const response = await customerApi.getCustomers();
    const payload = JSON.parse(response.data);

    const keys = Object.keys(payload[0]);
    const index = keys.indexOf("id");
    if (index > -1) {
      keys.splice(index, 1);
    }
    const filteredHeads = keys.filter((key) => availableHeads.includes(key));
    const formattedHeads = filteredHeads.map((head) => head.toUpperCase());
    formattedHeads.push("ACTIONS");
    setHeads(formattedHeads);

    const values = [];
    await payload.forEach((customer) => {
      const value = [];
      keys.forEach((key) => {
        if(key !== "id" && availableHeads.indexOf(key) != -1) {
          value.push(customer[key]);
        }
      });
      value.push(
        <div className={classes.actions}>
          <ObjectActions 
            handleDeleteClick={() => handleDeleteClick(customer.id)}
            handleViewClick={() => handleViewClick(customer.id)}
            handleEditClick={() => handleEditClick(customer.id)}
          />
        </div>
      );
      values.push(value);
    });
    setCustomers(values);
  };

  const handleViewClick = (id) => {
    historyRedirect(`/admin/customers/show/${id}`);
  };

  const handleEditClick = (id) => {
    historyRedirect(`/admin/customers/edit/${id}`);
  };

  const handleDelete = async (id) => {
    const response = await customerApi.deleteCustomer(id);
    if (response.status == 200) {
      fetchData();
      showSnackbar({
        color: "success",
        message: "Customer has been deleted successfully",
      });
    }
  };

  const handleDeleteClick = (id) => {
    showDialog({
      title: "Delete Customer",
      message: "Are you sure you want to delete this customer?",
      onConfirm: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title="Customers" subtitle="List of all customers" object="customers" actions={["create"]}>
      <Table
        tableHeaderColor="success"
        tableHead={heads}
        tableData={customers}
      />
    </Page>
  );
}
