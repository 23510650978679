import { request, get, put, post } from '../utils/request';

export const getCustomers = async () => {
    return get('/customer');
};

export const getCustomer = async (id) => {
    return get(`/customer/${id}`);
}

export const updateCustomer = async (id, data) => {
    return put(`/customer/${id}`, data);
}

export const createCustomer = async (data) => {
    return post('/customer', data);
}

export const deleteCustomer = async (id) => {
    return request({
        url: `/customer/${id}`,
        method: 'delete',
    });
}
