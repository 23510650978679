import React, {useState, useEffect} from "react";
import Page from "components/Page/Page";
import { useParams } from "react-router-dom";
import * as customerApi from "api/customer.api";
import * as actionApi from "api/action.api";
import { TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import { useStyles } from "./styles";
import CustomLink from "components/CustomLink/CustomLink";
import { isObject } from "utils/helper";
import CustomAccordion from "components/CustomAccordion/CustomAccordion";
import CustomTable from "components/Table/Table";

export default function CustomerShow(){
  const classes = useStyles();
  const params = useParams();
  const id = params.id;
  const [data, setData] = useState({});
  const [actionsTableHead, setActionsTableHead] = useState([]);
  const [actionsTableBody, setActionsTableBody] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await customerApi.getCustomer(id);
    const payload = JSON.parse(response.data);
    delete payload.id;
    setData(payload);

    const actionsResponse = await actionApi.getActionsByCustomer(id);
    const actionsPayload = JSON.parse(actionsResponse.data);
    if(actionsPayload.length > 0){
      const actionsTableBodyData = [];
      let income = 0;
      let expense = 0;
      actionsPayload.map((action) => {
        delete action.account_id;
        delete action.payer;
        action["account"] = (
          <CustomLink href={"/admin/accounts/show/"+action['accountData']['id']}>
            {action.accountData.name}
          </CustomLink>
        );
        delete action.accountData;
        if(isObject(action.payerData)){
          action["payer"] = (
            <CustomLink href={"/admin/customers/show/"+action['payerData']['id']}>
              {action.payerData.name}
            </CustomLink>
          );
        } else {
          action["payer"] = action.payerData;
        }
        delete action.payerData;
        action["title"] = (
          <CustomLink href={"/admin/actions/show/"+action['id']}>
            {action.title}
          </CustomLink>
        );
        delete action.id;
        delete action.description;
        delete action.files;
        const keys = Object.keys(action);
        actionsTableBodyData.push([action[keys[0]], action[keys[1]], action[keys[2]], action[keys[3]], action[keys[4]], action[keys[5]]]);

        if(action.amount > 0){
          income += parseInt(action.amount);
        } else {
          expense += parseInt(action.amount);
        }
      });
      setActionsTableBody(actionsTableBodyData);
      setActionsTableHead(Object.keys(actionsPayload[0]));
      setTotalIncome(income);
      setTotalExpense(expense);
    }
  }

  return (
    <Page title={data.name} object="customers" id={id} actions={["edit", "delete", "list"]} subtitle={data.company}>
      <TableContainer>
          <Table sx={{ minWidth: 500 }}>
           <colgroup>
              <col style={{width:'20%'}}/>
              <col style={{width:'80%'}}/>
            </colgroup>
            <TableBody>
              {Object.keys(data).map((key) => (
                <TableRow key={key}>
                  <TableCell className={classes.detailsKey}>{key.toUpperCase()}</TableCell>
                  <TableCell>{data[key]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
      </TableContainer>
      {actionsTableBody.length > 0 && (
      <div style={{marginTop: "15px"}}>
        <CustomAccordion title="Actions Summary" color="success">
          <TableContainer>
            <Table sx={{ minWidth: 500 }}>
              <colgroup>
                <col style={{width:'20%'}}/>
                <col style={{width:'80%'}}/>
              </colgroup>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.detailsKey}>Total Income</TableCell>
                  <TableCell>{totalIncome}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.detailsKey}>Total Expense</TableCell>
                  <TableCell>{totalExpense}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.detailsKey}>Balance</TableCell>
                  <TableCell style={
                    totalIncome + totalExpense > 0 ? {color: "green"} : {color: "red"}
                  }>{totalIncome + totalExpense}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CustomAccordion>
        <CustomAccordion title="Actions" color="success">
          <CustomTable
            style={{marginTop: "20px"}}
            tableHeaderColor="danger"
            tableHead={actionsTableHead}
            tableData={actionsTableBody}
          />
        </CustomAccordion>
      </div>
      )}
    </Page>
  );
}