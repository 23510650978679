import React from 'react';
import Button from 'components/CustomButtons/Button.js';
import { PropTypes } from 'prop-types';
import { useStyles } from './styles';

export default function ButtonAtRight(props) {
  const classes = useStyles();
  const size = props.size ? props.size : "lg";
  const color = props.color ? props.color : "primary";
  const handleClick = props.handleClick ? props.handleClick : () => {};
  const type = props.type ? props.type : "button";
  const style = props.style ? props.style : {};
	return (
		<div className={classes.buttonOuter}>
			<Button color={color} size={size} onClick={handleClick} type={type} style={style}>
				{props.children}
			</Button>
		</div>
	);
}

ButtonAtRight.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "transparent"
  ]),
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  children: PropTypes.node
};