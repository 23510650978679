import React, { useContext, useState, useEffect } from 'react';
import * as accountApi from 'api/account.api';
import { SnackbarContext } from 'contexts';
import ButtonAtRight from 'components/CustomButtons/ButtonAtRight';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Page from 'components/Page/Page';
import inputParams from './inputParams';
import history from 'utils/history';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import * as currencyApi from 'api/currency.api';
import * as accountGroupApi from 'api/accountGroup.api';

export default function AccountCreate() {
	const { showSnackbar } = useContext(SnackbarContext);

  const [currencies, setCurrencies] = useState([]);
  const [accountGroups, setAccountGroups] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const responseCurrencies = await currencyApi.getCurrencies();
    const payloadCurrencies = JSON.parse(responseCurrencies.data);
    const currenciesArr = payloadCurrencies.map((currency) => {
      return {
        value: currency.id,
        label: currency.name,
      };
    });
    setCurrencies(currenciesArr);

    const responseAccountGroups = await accountGroupApi.getAccountGroups();
    const payloadAccountGroups = JSON.parse(responseAccountGroups.data);
    const accountGroupsArr = payloadAccountGroups.map((accountGroup) => {
      return {
        value: accountGroup.id,
        label: `${accountGroup.name} (${accountGroup.currency.symbol})`,
      };
    });
    setAccountGroups(accountGroupsArr);
  };

  const inputsDefaultValues = inputParams.reduce((acc, input) => {
		acc[input.name] = '';
		return acc;
	}, {});

	const [inputs, setInputs] = useState(inputsDefaultValues);

	const handleSubmit = async (e) => {
		e.preventDefault();
		
		const data = Object.keys(inputs).reduce((acc, key) => {
		if (inputs[key] !== '') {
			acc[key] = inputs[key];
		}
		return acc;
		}, {});
			const response = await accountApi.createAccount(data);
			const payload = JSON.parse(response.data);
			if (response.status == 201) {
				showSnackbar({
					color: 'success',
					message: `Account ${payload.name} has been created successfully. You're being redirected to the account's page.`,
				});
		setTimeout(() => {
			history.push(`/admin/accounts/show/${payload.id}`);
		}, 3000);
        
		}
	};

	const updateInput = (e) => {
		e.persist();
		setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
	};

	const inputPerLine = 4;
	const columnSize = 12 / inputPerLine;

	const inputsDivided = [];
	for (let i = 0; i < inputParams.length; i += inputPerLine) {
		inputsDivided.push(inputParams.slice(i, i + inputPerLine));
	}

	return (
		<Page title="Create Account" subtitle="Creating a new account" actions={["list"]} object="accounts">
			<form onSubmit={handleSubmit}>
				{inputsDivided.map((dividedInput, index) => (
					<GridContainer key={index}>
						{dividedInput.map((input, index) => (
							<GridItem
								xs={12}
								sm={12}
								md={columnSize}
								key={index}
							>
								{input.showOnCreate && (input.type === 'text' || input.type === 'number') ? (
								<CustomInput
									labelText={input.label}
									id={input.name}
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										name: input.name,
										value: inputs[input.name],
										onChange: updateInput,
										type: input.type,
										required: input.required,
									}}
								/>) : input.showOnCreate && input.type === 'select' && input.name === 'currency' ? (
                  <CustomSelect
                    labelText={input.label}
                    id={input.name}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    selectProps={{
                      name: input.name,
                      value: inputs[input.name],
                      onChange: updateInput,
                      required: input.required,
                    }}
                    options={currencies}
                  />
                ) : input.showOnCreate && input.type === 'select' && input.name === 'accountGroup' ? (
                  <CustomSelect
                    labelText={input.label}
                    id={input.name}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    selectProps={{
                      name: input.name,
                      value: inputs[input.name],
                      onChange: updateInput,
                      required: input.required,
                    }}
                    options={accountGroups}
                  />
                ) : null}
							</GridItem>
						))}
					</GridContainer>
				))}
				<ButtonAtRight
					color="success"
					type="submit"
					style={{ marginTop: '10px' }}
				>
					Create
				</ButtonAtRight>
			</form>
		</Page>
	);
}
