import { request, get, put, post } from '../utils/request';

export const getAccountGroups = async () => {
    return get('/account_group');
};

export const getAccountGroup = async (id) => {
    return get(`/account_group/${id}`);
}

export const updateAccountGroup = async (id, data) => {
    return put(`/account_group/${id}`, data);
}

export const createAccountGroup = async (data) => {
    return post('/account_group', data);
}

export const deleteAccountGroup = async (id) => {
    return request({
        url: `/account_group/${id}`,
        method: 'delete',
    });
}
