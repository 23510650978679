import React, { useState, useEffect } from 'react';
import ChartistGraph from 'react-chartist';
import * as request from 'utils/request';

import { makeStyles } from '@material-ui/core/styles';
// Icons
import AccessTime from '@material-ui/icons/AccessTime';
import BugReport from '@material-ui/icons/BugReport';
import Code from '@material-ui/icons/Code';
import Cloud from '@material-ui/icons/Cloud';
import Add from '@material-ui/icons/Add';
// Components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Tasks from 'components/Tasks/Tasks.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';


import { bugs, website, server } from 'variables/general.js';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';

import * as analyticsApi from 'api/analytics.api';
import * as settingApi from 'api/setting.api';
import { formatMoney } from 'utils/helper';
import MultitypeChart from 'components/CustomCharts/MultitypeChart/MultitypeChart';
import { Button, IconButton } from '@material-ui/core';
import { historyRedirect } from 'utils/helper';
import BarChart from 'components/CustomCharts/BarChart/BarChart';
import { redColor } from 'utils/helper';

const useStyles = makeStyles(styles);

const themeColors = [
	"success",
	"warning",
	"primary",
	"danger",
	"info",
	"rose",
];

const thisMonth = new Date().getMonth();

const monthLabelsConst = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default function Dashboard() {
	const [accounts, setAccounts] = useState([]);
	const [defaultCurrency, setDefaultCurrency] = useState({});
	const [yearTotal, setYearTotal] = useState({
    balance: 0,
    income: 0,
    expense: 0,
  });
  const [thisMonthTotal, setThisMonthTotal] = useState({
    balance: 0,
    income: 0,
    expense: 0,
  });
  const [lastMonthTotal, setLastMonthTotal] = useState({
    balance: 0,
    income: 0,
    expense: 0,
  });

	const [yearAnalytics, setYearAnalytics] = useState({
		labels: monthLabelsConst.slice(0, thisMonth + 1),
		datasets: [
			{
				type: 'line',
				label: 'Balance',
				borderColor: 'rgb(255, 99, 132)',
				borderWidth: 2,
				fill: false,
				data: [],
			},
		],
	});

  const [monthAnalytics, setMonthAnalytics] = useState({
		labels: [monthLabelsConst[thisMonth]],
		datasets: [
			{
				type: 'bar',
				label: 'Balance',
				backgroundColor: 'rgb(255, 99, 132)',
				data: [],
			},
		],
	});

  const [lastMonthAnalytics, setLastMonthAnalytics] = useState({
		labels: [monthLabelsConst[0]],
		datasets: [
			{
				type: 'bar',
				label: 'Balance',
				backgroundColor: 'rgb(255, 99, 132)',
				data: [],
			},
		],
	});

  const [categoryAnalytics, setCategoryAnalytics] = useState({
		labels: [monthLabelsConst[thisMonth]],
		datasets: [
			{
				type: 'bar',
				label: 'Balance',
				backgroundColor: 'rgb(255, 99, 132)',
				data: [],
			},
		],
	});

  const colorGen = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return "rgb(" + r + "," + g + "," + b + ")";
  }

	const fetchData = async () => {
		let accountsResponse = await request.get('/account');
		accountsResponse = JSON.parse(accountsResponse.data);
		setAccounts(accountsResponse);
		
		let defaultCurrencyResponse = await request.get('/currency/default');
		defaultCurrencyResponse = JSON.parse(defaultCurrencyResponse.data);
		setDefaultCurrency(defaultCurrencyResponse);

    const responseExpenseAlert = await settingApi.getSetting('expense_alert');
    const payloadExpenseAlert = JSON.parse(responseExpenseAlert.data);
    const expenseAlert = payloadExpenseAlert.value;

    	//:y this year analytics
		const thisYearAnalytics = await analyticsApi.getThisYear();
		const thisYearPayload = JSON.parse(thisYearAnalytics.data);

		let monthLabels = [];
		let valueCount = Object.keys(thisYearPayload).length;
		if(valueCount != 12){
		  monthLabels = monthLabelsConst.slice(0, valueCount + 1);
		} else {
      monthLabelsConst.push(monthLabelsConst[0])
		  monthLabels = monthLabelsConst;
		}

		const balances = [];
		const expenses = [];
		const incomes = [];
		Object.keys(thisYearPayload).forEach((key) => {
			balances.push(parseInt(thisYearPayload[key].balance));
			expenses.push(parseInt(thisYearPayload[key].expenses));
			incomes.push(parseInt(thisYearPayload[key].incomes));
		});
    setYearTotal({
      balance: balances.reduce((a, b) => a + b, 0),
      income: incomes.reduce((a, b) => a + b, 0),
      expense: expenses.reduce((a, b) => a + b, 0),
    });
		balances.forEach((balance, index) => {
			if (balance === 0 && expenses[index] === 0 && incomes[index] === 0) {
        balances[index] = null;
				expenses[index] = null;
				incomes[index] = null;
        monthLabels[index] = null;
			}
		});

		expenses.push(0);
		incomes.push(0);
		
		const yearAnalyticsData = {
			labels: monthLabels,
			datasets: [
				{
					type: 'line',
					label: 'Max Expenses Alert',
					borderColor: 'rgb(255, 0, 0)',
					borderWidth: 2,
					fill: true,
					data: Array(balances.length + 1).fill(expenseAlert * -1),
				},
				{
					type: 'line',
					label: 'Balance',
					borderColor: 'rgb(255, 100, 192)',
					borderWidth: 2,
					fill: false,
					data: balances,
				},
				{
					type: 'bar',
					label: 'Expenses',
					backgroundColor: 'rgb(255, 50, 50)',
					data: expenses,
				},
				{
					type: 'bar',
					label: 'Incomes',
					backgroundColor: 'rgb(53, 162, 235)',
					borderColor: "white",
					borderWidth: 2,
					data: incomes,
				},
				
			],
		};
		setYearAnalytics(yearAnalyticsData);

    //:y this month analytics
    const thisMonthAnalytics = await analyticsApi.getThisMonth();
    const thisMonthPayload = JSON.parse(thisMonthAnalytics.data);
    const expenseColor = redColor(thisMonthPayload.expenses * -1, expenseAlert);
    const thisMonthAnalyticsData = {
      labels: [monthLabelsConst[thisMonth]],
      datasets: [
        {
          type: 'bar',
          label: 'Incomes',
          backgroundColor: 'rgb(53, 162, 235)',
          borderColor: "white",
          borderWidth: 2,
          data: [thisMonthPayload.incomes],
        },
        {
          type: 'bar',
          label: 'Expenses',
          backgroundColor: expenseColor,
          data: [thisMonthPayload.expenses],
        },
        {
          type: 'bar',
          label: 'Balance',
          backgroundColor: 'rgb(255, 100, 192)',
          data: [thisMonthPayload.balance],
        },
		
      ],
    };
    setMonthAnalytics(thisMonthAnalyticsData);
    setThisMonthTotal({
      balance: thisMonthPayload.balance,
      income: thisMonthPayload.incomes,
      expense: thisMonthPayload.expenses,
    });

    //:y last month analytics
    const lastMonthAnalytics = await analyticsApi.getLastMonth();
    const lastMonthPayload = JSON.parse(lastMonthAnalytics.data);
    const lastMonthIndex = thisMonth === 0 ? 11 : thisMonth - 1;
    const lastMonthAnalyticsData = {
      labels: [monthLabelsConst[lastMonthIndex]],
      datasets: [
        {
          type: 'bar',
          label: 'Incomes',
          backgroundColor: 'rgb(53, 162, 235)',
          borderColor: "white",
          borderWidth: 2,
          data: [lastMonthPayload.incomes],
        },
        {
          type: 'bar',
          label: 'Expenses',
          backgroundColor: 'rgb(255, 50, 50)',
          data: [lastMonthPayload.expenses],
        },
        {
          type: 'bar',
          label: 'Balance',
          backgroundColor: 'rgb(255, 100, 192)',
          data: [lastMonthPayload.balance],
        },
      ],
    };
    setLastMonthAnalytics(lastMonthAnalyticsData);
    setLastMonthTotal({
      balance: lastMonthPayload.balance,
      income: lastMonthPayload.incomes,
      expense: lastMonthPayload.expenses,
    });

    //:y category analytics
    const categoryAnalytics = await analyticsApi.getCategories();
    const categoryPayload = JSON.parse(categoryAnalytics.data);
    const categoryLabels = [];
    const categoryExpenses = [];
    Object.keys(categoryPayload).forEach((key) => {
      categoryLabels.push(key);
      categoryExpenses.push(parseInt(categoryPayload[key]) * -1);
    });

    const categoryColors = [];
    categoryLabels.forEach(() => {
      categoryColors.push(colorGen());
    });

    const datasets = [];
    categoryLabels.forEach((label, index) => {
      datasets.push({
        type: 'bar',
        label: label,
        backgroundColor: categoryColors[index],
        data: [categoryExpenses[index]],
      });
    });

    const categoryAnalyticsData = {
      labels: monthLabelsConst[thisMonth],
      datasets: datasets,
    };
    setCategoryAnalytics(categoryAnalyticsData);
    
	};
	
	useEffect(() => {
		fetchData();
	}, []);

  const handleAddActionClick = (accountId) => {
    historyRedirect('/admin/actions/create/' + accountId);
  };
	

	const classes = useStyles();
	
	return (
		<div>
			<GridContainer>
				{ accounts.map((account, account_key) => (
					<GridItem xs={12} sm={6} md={3} key={"accountGrid-" + account_key}>
						<Card>
							<CardHeader color={ themeColors[account_key] } stats icon>
								<CardIcon color={ themeColors[account_key] } style={{ fontSize: '2rem' }}>
									{ account.currency.symbol }
								</CardIcon>
                <Button variant='contained' color="primary" onClick={() => handleAddActionClick(account.id)}
                  size="small"
                  style={{ 
                    position: 'absolute', top: '-10px', right: '0',
                    maxWidth: "40px", maxHeight: "40px",
                    minWidth: "40px", minHeight: "40px",
                    borderRadius: "50%", padding: "1px 5px 5px 5px",
                  }}
                >
                  <Add fontSize='small' />
                </Button>
                
                
								<p className={classes.cardCategory}>{ account.category }</p>
								<h4 className={classes.accountName}>
									{ account.name }
								</h4>
                <h2 className={classes.accountBalance}>
                  { formatMoney(account.balance) } <small>{ account.currency.symbol }</small>
                </h2>
							</CardHeader>
							<CardFooter stats>
								<div className={classes.defaultCurrencyEquivalent}>
								  { formatMoney(account.defaultCurrencyEquivalent) } { defaultCurrency.symbol }
								</div>
							</CardFooter>
						</Card>
					</GridItem>
				))}				
			</GridContainer>
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<Card>
						<CardHeader color="info">
							<h4 className={classes.cardTitleWhite}>
								This Year
							</h4>
							<p className={classes.cardCategoryWhite} style={{color: "#0d7fc0"}}>
								Balance: { formatMoney(parseInt(yearTotal.balance)) } { defaultCurrency.symbol }
							</p>
              <p className={classes.cardCategoryWhite} style={{color: "#0fff00"}}>
                Incomes: { formatMoney(parseInt(yearTotal.income)) } { defaultCurrency.symbol }
              </p>
              <p className={classes.cardCategoryWhite} style={{color: "red"}}>
                Expenses: { formatMoney(parseInt(-1 * yearTotal.expense)) } { defaultCurrency.symbol }
              </p>
						</CardHeader>
						<CardBody>
							<MultitypeChart data={yearAnalytics} />
						</CardBody>
					</Card>
				</GridItem>
        <GridItem xs={12} sm={12} md={6}>
					<Card>
						<CardHeader color="success">
							<h4 className={classes.cardTitleWhite}>
								This Month
							</h4>
							<p className={classes.cardCategoryWhite} style={{color: "#0d7fc0"}}>
                Balance: { formatMoney(parseInt(thisMonthTotal.balance)) } { defaultCurrency.symbol }
							</p>
              <p className={classes.cardCategoryWhite} style={{color: "#0fff00"}}>
                Incomes: { formatMoney(parseInt(thisMonthTotal.income)) } { defaultCurrency.symbol }
              </p>
              <p className={classes.cardCategoryWhite} style={{color: "red"}}>
                Expenses: { formatMoney(parseInt(-1 * thisMonthTotal.expense)) } { defaultCurrency.symbol }
              </p>
						</CardHeader>
						<CardBody>
							<BarChart title="This Month" data={monthAnalytics} />
						</CardBody>
					</Card>
				</GridItem>
        <GridItem xs={12} sm={12} md={6}>
					<Card>
						<CardHeader color="warning">
							<h4 className={classes.cardTitleWhite}>
								Last Month
							</h4>
							<p className={classes.cardCategoryWhite} style={{color: "#0d7fc0"}}>
                Balance: { formatMoney(parseInt(lastMonthTotal.balance)) } { defaultCurrency.symbol }
							</p>
              <p className={classes.cardCategoryWhite} style={{color: "#0fff00"}}>
                Incomes: { formatMoney(parseInt(lastMonthTotal.income)) } { defaultCurrency.symbol }
              </p>
              <p className={classes.cardCategoryWhite} style={{color: "red"}}>
                Expenses: { formatMoney(parseInt(-1 * lastMonthTotal.expense)) } { defaultCurrency.symbol }
              </p>
						</CardHeader>
						<CardBody>
							<BarChart title="Last Month" data={lastMonthAnalytics} />
						</CardBody>
					</Card>
				</GridItem>
        <GridItem xs={12} sm={12} md={6}>
					<Card>
						<CardHeader color="danger">
							<h4 className={classes.cardTitleWhite}>
								This Month Expenses Categories
							</h4>
							<p className={classes.cardCategoryWhite} style={{color: "0d7fc0"}}>
								Expenses: { formatMoney(parseInt(-1 * thisMonthTotal.expense)) } { defaultCurrency.symbol }
							</p>
						</CardHeader>
						<CardBody>
							<BarChart title="This Month Expenses Categories" data={categoryAnalytics} />
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<CustomTabs
						title="Tasks:"
						headerColor="primary"
						tabs={[
							{
								tabName: 'Bugs',
								tabIcon: BugReport,
								tabContent: (
									<Tasks
										checkedIndexes={[0, 3]}
										tasksIndexes={[0, 1, 2, 3]}
										tasks={bugs}
									/>
								),
							},
							{
								tabName: 'Website',
								tabIcon: Code,
								tabContent: (
									<Tasks
										checkedIndexes={[0]}
										tasksIndexes={[0, 1]}
										tasks={website}
									/>
								),
							},
							{
								tabName: 'Server',
								tabIcon: Cloud,
								tabContent: (
									<Tasks
										checkedIndexes={[1]}
										tasksIndexes={[0, 1, 2]}
										tasks={server}
									/>
								),
							},
						]}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<Card>
						<CardHeader color="warning">
							<h4 className={classes.cardTitleWhite}>
								Employees Stats
							</h4>
							<p className={classes.cardCategoryWhite}>
								New employees on 15th September, 2016
							</p>
						</CardHeader>
						<CardBody>
							<Table
								tableHeaderColor="warning"
								tableHead={['ID', 'Name', 'Salary', 'Country']}
								tableData={[
									['1', 'Dakota Rice', '$36,738', 'Niger'],
									[
										'2',
										'Minerva Hooper',
										'$23,789',
										'Curaçao',
									],
									[
										'3',
										'Sage Rodriguez',
										'$56,142',
										'Netherlands',
									],
									[
										'4',
										'Philip Chaney',
										'$38,735',
										'Korea, South',
									],
								]}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			
		</div>
	);
}
