import React, {useContext} from "react";
import { useStyles } from "./styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from 'components/CustomButtons/Button.js';
import { Edit, Delete, Visibility, AddCircleOutlineOutlined, ArrowBack } from "@material-ui/icons";
import history from "utils/history";
import * as customerApi from "api/customer.api";
import { DialogContext, SnackbarContext } from "contexts";

export default function Page(props){
    const classes = useStyles();

    const {showDialog} = useContext(DialogContext);
    const {showSnackbar} = useContext(SnackbarContext);

    const handleCreateClick = () => {
      history.push(`/admin/${props.object}/create`);
    };

    const handleViewClick = () => {
      history.push(`/admin/${props.object}/show/${props.id}`);
    };

    const handleEditClick = () => {
      history.push(`/admin/${props.object}/edit/${props.id}`);
    };

    const handleDeleteClick = () => {
      showDialog({
        title: "Delete",
        message: "Are you sure you want to delete this object?",
        onConfirm: () => handleDelete(),
      });
    };
    const handleDelete = async () => {
      const response = await customerApi.deleteCustomer(props.id);
      if (response.status === 200) {
        showSnackbar({
          color: "success",
          message: "Object has been deleted successfully. You're being redirected...",
        });
        setTimeout(() => {
          history.push(`/admin/${props.object}/list`);
        }, 3000);
      }
    }

    const handleReturnClick = () => {
      history.push(`/admin/${props.object}/list`);
    };

    const head = props.head != "success" && props.head ? props.head : "success";

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color={head}>
              <div className="cardMain" style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                <div className="cardLeft">
                  <h4 className={classes.cardTitleWhite}>{props.title}</h4>
                  <p className={classes.cardCategoryWhite}>
                    {props.subtitle}
                  </p>
                </div>
                {props.object ? (
                <div className="cardActions">
                  {props.actions.includes("view") ? (
                  <Button title="View" size="sm" color="info" onClick={handleViewClick}>
                    <Visibility />
                  </Button>
                  ) : null}
                  {props.actions.includes("edit") ? (
                  <Button title="Edit" size="sm" color="info" onClick={handleEditClick}>
                    <Edit />
                  </Button>
                  ) : null}
                  {props.actions.includes("delete") ? (
                  <Button title="Delete" size="sm" color="danger" onClick={handleDeleteClick}>
                    <Delete />
                  </Button>
                  ) : null}
                  {props.actions.includes("create") ? (
                  <Button title="Create" size="sm" color="info" onClick={handleCreateClick}>
                    <AddCircleOutlineOutlined />
                  </Button>
                  ) : null}
                  {props.actions.includes("list") ? (
                  <Button title="List" size="sm" color="info" onClick={handleReturnClick}>
                    <ArrowBack />
                  </Button>
                  ) : null}
                </div>
                ) : null}
              </div>
            </CardHeader>
            <CardBody>
              {props.children}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
}