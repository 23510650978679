import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import styles from "assets/jss/material-dashboard-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomFile(props) {
  const classes = useStyles();
	const {
		formControlProps,
    onChange,
    initialFiles = [],
	} = props;

  const onFilesChange = (files) => {
    onChange(files);
  }

	return (
    <FormControl 
      {...formControlProps}
      className={formControlProps.className + " " + classes.formControl}
      sx={{m: 1, minWidth: 120}}>
      <DropzoneArea
        onChange={onFilesChange}
        filesLimit={10}
        initialFiles={initialFiles}
      />
    </FormControl>
  );
}

CustomFile.propTypes = {
  formControlProps: PropTypes.object,
  labelText: PropTypes.string,
  id: PropTypes.any,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
    })
  ),
  helperText: PropTypes.string,
};
