import { request, get, put, post } from '../utils/request';

export const login = async (credentials) => {
	return request({
		url: '/login',
		method: 'post',
		data: {
			username: credentials.username,
			password: credentials.password,
		},
	}).then((res) => {
		console.log(res);
	});
};

export const me = async () => {
	return get('/user');
}

export const update = async (data) => {
	return put('/user', data);
};

export const updatePassword = async (data) => {
	return post('/user/change_password', data);
}
