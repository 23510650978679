import React from "react";
import LoanCreate from "./Create";
import LoanEdit from "./Edit";
import LoansList from "./List";
import LoanShow from "./Show";

export default function Loans(view){
    if(view === "show"){
        return <LoanShow />;
    } else if (view === "list"){
        return <LoansList />;
    } else if (view === "create"){
        return <LoanCreate />;
    } else if (view === "edit"){
        return <LoanEdit />;
    }

    return <></>
}