import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider} from 'react-redux';
import history from 'utils/history';
import store from 'store';
import { getToken } from 'auth/token';
import { fetchUserData } from 'auth/authThunk';
import Login from 'layouts/Login';
import App from 'layouts/App';
import axios from 'axios';

import 'assets/css/material-dashboard-react.css?v=1.9.0';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

axios.defaults.baseURL = process.env.REACT_APP_SERVER;

if (getToken()) {
	store.dispatch(fetchUserData());
	axios.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
}

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<Switch>
				<Route path="/login" component={Login}/>
				<Route path="/admin" component={App}/>
				<Redirect from="/" to="/admin/home" />
			</Switch>
		</Router>
	</Provider>,
	document.getElementById('root')
);

serviceWorkerRegistration.register();