import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMoreRounded } from '@material-ui/icons';
import {
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography 
} from '@material-ui/core';
const styles = {
  primary: {
    backgroundColor: '#9c27b0',
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0 rgba(156, 39, 176, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4)',
  },
  info: {
    backgroundColor: '#00acc1',
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0 rgba(0, 172, 193, 0.14), 0 7px 10px -5px rgba(0, 172, 193, 0.4)',
  },
  success: {
    backgroundColor: '#4caf50',
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0 rgba(76, 175, 80, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)',
  },
  warning: {
    backgroundColor: '#ff9800',
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0 rgba(255, 152, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)',
  },
  danger: {
    backgroundColor: '#f44336',
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0 rgba(244, 67, 54, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4)',
  },
  rose: {
    backgroundColor: '#e91e63',
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0 rgba(233, 30, 99, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)',
  },
  gray: {
    backgroundColor: '#999999',
    color: '#FFFFFF',
    boxShadow:
      '0 4px 20px 0 rgba(153, 153, 153, 0.14), 0 7px 10px -5px rgba(153, 153, 153, 0.4)',
  },
};

const useStyles = makeStyles(styles);

export default function CustomAccordion(props) {
  const classes = useStyles();
	const {
		title,
    color = 'primary',
	} = props;

	return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreRounded className={classes[color]} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes[color]}
      >
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}

CustomAccordion.propTypes = {
  title: PropTypes.string,
};
