import React, { useState, useEffect } from "react";
import * as userApi from "api/user.api";
// core components
import { SnackbarContext } from "contexts/SnackbarContext";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import useStyles from "./styles";
import { useContext } from "react";

export default function UserProfile() {
  const classes = useStyles();
  
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const handleUsernameChange = (e) => setUsername(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleNameChange = (e) => setName(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  
  const fetchData = async () => {
    const response = await userApi.me();
    const payload = JSON.parse(response.data);
    setUsername(payload.username);
    setEmail(payload.email);
    setName(payload.name);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { showSnackbar } = useContext(SnackbarContext);

  const handleOnUpdate = async (e) => {
    e.preventDefault();

    if(name != "" || email != ""){
      const data = {
        name: name,
        email: email
      }; 
      const response = await userApi.update(data);
      if(response.status == 200){
        fetchData();
        showSnackbar({ 
          color: 'success',
          message: 'Your account has been updated successfully'
        });
      }
    }
    if(password != "" && newPassword != ""){
      const data = {
        password: password,
        "new_password": newPassword
      };
      
      const response = await userApi.updatePassword(data);
      if(response.status == 200){
        fetchData();
        showSnackbar({ 
          color: 'success',
          message: 'Your password has been updated successfully'
        });
      }
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <form onSubmit={handleOnUpdate}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
              <p className={classes.cardCategoryWhite}>Complete your profile</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Username"
                    id="username"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: username,
                      onChange: handleUsernameChange,
                      disabled: true,
                      required: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email address"
                    id="email-address"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: email,
                      onChange: handleEmailChange,
                      required: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: name,
                      onChange: handleNameChange
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Old Password"
                    id="old-password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      onChange: handlePasswordChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="New Password"
                    id="new-password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={newPassword}
                    inputProps={{
                      type: "password",
                      onChange: handleNewPasswordChange
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit">Update Profile</Button>
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
