import { request, get, post } from '../utils/request';

export const getActions = async () => {
    return get('/action');
};

export const getAction = async (id) => {
    return get(`/action/${id}`);
}

export const updateAction = async (id, data) => {
    return post(`/action/${id}?_method=PUT`, data);
}

export const createAction = async (data) => {
    return post('/action', data);
}

export const deleteAction = async (id) => {
    return request({
        url: `/action/${id}`,
        method: 'delete',
    });
}

export const getCategories = async () => {
    return get('/action/categories');
};

export const getActionsByAccount = async (id) => {
    return get(`/action/by_account/${id}`);
};

export const getActionsByCustomer = async (id) => {
    return get(`/action/by_customer/${id}`);
};
