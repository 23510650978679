import React, { useContext, useState } from 'react';
import * as customerApi from 'api/customer.api';
import { SnackbarContext } from 'contexts';
import ButtonAtRight from 'components/CustomButtons/ButtonAtRight';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Page from 'components/Page/Page';
import inputParams from './inputParams';
import history from 'utils/history';

export default function CustomerCreate() {
	const { showSnackbar } = useContext(SnackbarContext);

  const inputsDefaultValues = inputParams.reduce((acc, input) => {
		acc[input.name] = '';
		return acc;
	}, {});

	const [inputs, setInputs] = useState(inputsDefaultValues);

	const handleSubmit = async (e) => {
		e.preventDefault();
		
    const data = Object.keys(inputs).reduce((acc, key) => {
      if (inputs[key] !== '') {
        acc[key] = inputs[key];
      }
      return acc;
    }, {});
		const response = await customerApi.createCustomer(data);
		const payload = JSON.parse(response.data);
		if (response.status == 201) {
			showSnackbar({
				color: 'success',
				message: `Customer ${payload.name} has been created successfully. You're being redirected to the customer's page.`,
			});
      setTimeout(() => {
        history.push(`/admin/customers/show/${payload.id}`);
      }, 3000);
        
		}
	};

	const updateInput = (e) => {
		e.persist();
		setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
	};

	const inputPerLine = 4;
	const columnSize = 12 / inputPerLine;

	const inputsDivided = [];
	for (let i = 0; i < inputParams.length; i += inputPerLine) {
		inputsDivided.push(inputParams.slice(i, i + inputPerLine));
	}

	return (
		<Page title="Create Customer" subtitle="Creating a new customer" actions={["list"]} object="customers">
			<form onSubmit={handleSubmit}>
				{inputsDivided.map((dividedInput, index) => (
					<GridContainer key={index}>
						{dividedInput.map((input, index) => (
							<GridItem
								xs={12}
								sm={12}
								md={columnSize}
								key={index}
							>
								<CustomInput
									labelText={input.label}
									id={input.name}
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										name: input.name,
										value: inputs[input.name],
										onChange: updateInput,
										type: input.type,
										required: input.required,
									}}
								/>
							</GridItem>
						))}
					</GridContainer>
				))}
				<ButtonAtRight
					color="success"
					type="submit"
					style={{ marginTop: '10px' }}
				>
					Create
				</ButtonAtRight>
			</form>
		</Page>
	);
}
