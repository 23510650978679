import React, {useState, useEffect, useContext} from "react";
import Page from "components/Page/Page";
import { useParams } from "react-router-dom";
import { DialogContext, SnackbarContext } from "contexts";
import { TableContainer, Table, TableBody, TableRow, TableCell } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useStyles } from "./styles";
import ButtonAtRight from "components/CustomButtons/ButtonAtRight";
import * as accountApi from "api/account.api";
import * as accountGroupApi from "api/accountGroup.api";
import * as currencyApi from "api/currency.api";
import inputParams from "./inputParams";
import CustomSelect from "components/CustomSelect/CustomSelect";

export default function AccountEdit(){
  const classes = useStyles();

  const params = useParams();
  const [inputs, setInputs] = useState({});
  const [currency, setCurrency] = useState(1);
  const [accountGroup, setAccountGroup] = useState(1);

  const {showSnackbar} = useContext(SnackbarContext);
  const [currencies, setCurrencies] = useState([]);
  const [accountGroups, setAccountGroups] = useState([]);

  const updateInput = (e) => {
    e.persist();
    setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await accountApi.getAccount(params.id);
    const payload = JSON.parse(response.data);
    delete payload.id;
    setInputs(payload);
    setCurrency(payload.currency.id);
    setAccountGroup(payload.accountGroup.id);
    // console.log(payload);

    const responseCurrencies = await currencyApi.getCurrencies();
    const payloadCurrencies = JSON.parse(responseCurrencies.data);
    const currenciesArr = payloadCurrencies.map((currency) => {
      return {
        value: currency.id,
        label: currency.name,
      };
    });
    setCurrencies(currenciesArr);

    const responseAccountGroups = await accountGroupApi.getAccountGroups();
    const payloadAccountGroups = JSON.parse(responseAccountGroups.data);
    const accountGroupsArr = payloadAccountGroups.map((accountGroup) => {
      return {
        value: accountGroup.id,
        label: `${accountGroup.name} (${accountGroup.currency.symbol})`,
      };
    });
    setAccountGroups(accountGroupsArr);
  }

  const handleCurrencySelectChange = e => setCurrency(e.target.value);
  const handleAccountGroupSelectChange = e => setAccountGroup(e.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();
    inputs.currency_id = currency;
    inputs.account_group_id = accountGroup;
    const response = await accountApi.updateAccount(params.id, inputs);
    if(response.status === 200){
      const payload = JSON.parse(response.data);
      showSnackbar({
        color: "success",
        message: `Account ${payload.name} updated successfully`
      });
    } else {
      showSnackbar({ 
        color: "error", 
        message: "Error updating Account"
      });
    }
  }

  return (
    <Page title={inputs.name} subtitle={inputs.name + " is being edited"} object="accounts" id={params.id} actions={["view", "delete", "list"]}>
      <TableContainer>
        <form onSubmit={handleSubmit}>
          <Table sx={{ minWidth: 500 }}>
            <colgroup>
              <col style={{width:'20%'}}/>
              <col style={{width:'80%'}}/>
            </colgroup>
            <TableBody>
              {inputParams.map((inputParam, inputIndex) => (
                inputParam.showOnEdit && (
                  <TableRow key={inputIndex}>
                    <TableCell className={classes.tableCell}>{inputParam.label}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {inputParam.type === "select" ? 
                        inputParam.name === "currency" ? (
                          <CustomSelect
                            labelText={inputParam.label}
                            id={inputParam.name}
                            formControlProps={{
                              fullWidth: true
                            }}
                            selectProps={{
                              name: inputParam.name,
                              value: currency,
                              onChange: handleCurrencySelectChange,
                              required: inputParam.required,
                            }}
                            options={currencies}
                          />
                        ) : inputParam.name === "accountGroup" ? (
                          <CustomSelect
                            labelText={inputParam.label}
                            id={inputParam.name}
                            formControlProps={{
                              fullWidth: true
                            }}
                            selectProps={{
                              name: inputParam.name,
                              value: accountGroup,
                              onChange: handleAccountGroupSelectChange,
                              required: inputParam.required,
                            }}
                            options={accountGroups}
                          />
                        ) : null
                      : (
                        <CustomInput
                          
                          id={inputParam.name}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: inputParam.name,
                            value: inputs[inputParam.name],
                            onChange: updateInput,
                            type: inputParam.type,
                            required: inputParam.required
                          }}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )
              ))}
            </TableBody>
          </Table>
          <ButtonAtRight color="success" type="submit" style={{marginTop: "10px"}}>
            Update
          </ButtonAtRight>
        </form>
      </TableContainer>
    </Page>
  );
}