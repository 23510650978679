import { request, get, put, post } from '../utils/request';

export const getCurrencies = async () => {
    return get('/currency');
};

export const getCurrency = async (id) => {
    return get(`/currency/${id}`);
};

export const updateCurrency = async (id, data) => {
    return put(`/currency/${id}`, data);
};

export const createCurrency = async (data) => {
    return post('/currency', data);
};

export const deleteCurrency = async (id) => {
    return request({
        url: `/currency/${id}`,
        method: 'delete',
    });
};

export const setDefaultCurrency = async (id) => {
    return put(`/currency/set_default`, { id: id});
};

export const convert = async (from, to, amount=1) => {
    return get(`/currency/convert?from=${from}&to=${to}&amount=${amount}`);
};
