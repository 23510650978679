const inputParams = [
	{
		name: 'name',
		label: 'Name',
		type: 'text',
		value: '',
		required: true,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: 'name',
	},
	{
		name: "currency",
		label: "Currency",
		type: "select",
		value: "1",
		required: true,
		to: ["symbol", "name"],
		showOnCreate: true,
		showOnEdit: true,
		databaseName: "currency_id",
	},
	{
		name: "start_balance",
		label: "Starting Balance",
		type: "number",
		value: "0.00",
		required: false,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: "start_balance",
	},
	{
		name: "balance",
		label: "Balance",
		type: "number",
		value: "0.00",
		required: false,
		showOnCreate: true,
		showOnEdit: true,
		databaseName: "balance",
	},
	{
		name: "accountGroup",
		label: "Account Group",
		type: "select",
		value: "1",
		required: false,
		to: "name",
		showOnCreate: true,
		showOnEdit: true,
		databaseName: "account_group_id",
	},
	{
		name: "defaultCurrencyEquivalent",
		label: "Default Currency Equivalent",
		type: "number",
		value: "0.00",
		required: false,
		showOnCreate: false,
		showOnEdit: false,
		databaseName: "defaultCurrencyEquivalent",
	}
];

export default inputParams;
