import React from 'react';
import Button from 'components/CustomButtons/Button.js';
import { Edit, Delete, Visibility, CompareArrows } from "@material-ui/icons";

export default function ObjectActions(props) {
	return (
		<div>
			<Button title="View" size="sm" color="info" onClick={props.handleViewClick}>
        <Visibility />
      </Button>
      <Button title="Edit" size="sm" color="success" onClick={props.handleEditClick}>
        <Edit />
      </Button>
      <Button title="Delete" size="sm" color="danger" onClick={props.handleDeleteClick}>
        <Delete />
      </Button>
      {props.addAction && (
        <Button title="Add Action" size="sm" color="primary" onClick={props.handleAddActionClick}>
          <CompareArrows />
        </Button>
      )}
		</div>
	);
}
