import React, {useState, useEffect, useContext} from "react";
import Page from "components/Page/Page";
import { useParams } from "react-router-dom";
import { SnackbarContext } from "contexts";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useStyles } from "./styles";
import ButtonAtRight from "components/CustomButtons/ButtonAtRight";
import CustomSelect from "components/CustomSelect/CustomSelect";

import * as currencyApi from "api/currency.api";
import * as loanApi from "api/loan.api";
import { isObject } from "utils/helper";
import { getImage } from "api/image.api";
import dayjs from "dayjs";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomAutocomplete from "components/CustomAutocomplete/CustomAutocomplete";
import CustomDatetimePicker from "components/CustomDatetimePicker/CustomDatetimePicker";
import CustomFile from "components/CustomFile/CustomFile";
import { historyRedirect } from "utils/helper";
import { doesExist } from "utils/helper";

export default function LoanEdit(){
  const classes = useStyles();
  const params = useParams();

  //* States
  const {showSnackbar} = useContext(SnackbarContext);
  const [loan, setLoan] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState(1);
  const [date, setDate] = useState(dayjs(new Date()).format("YYYY-MM-DD HH:mm"));
  const [dueDate, setDueDate] = useState(dayjs(new Date()).format("YYYY-MM-DD HH:mm"));
  const [payer, setPayer] = useState('');
  const [payerOptions, setPayerOptions] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isFilesFetched, setIsFilesFetched] = useState(false);


  //* Fetch data
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await loanApi.getLoan(params.id);
    const payload = JSON.parse(response.data);
    setLoan(payload);
    delete payload.id;
    setTitle(payload.title);
    setDescription(payload.description);
    setAmount(payload.amount);
    setCurrency(payload.currency_id);
    setDate(payload.date);
    setDueDate(payload.due_date);

    let payer = "";
    if(isObject(payload.payerData)){
      payer = payload.payerData.name;
    } else {
      payer = payload.payer;
    }
    setPayer(payer);

    const allFiles = [];
    if(payload.files.length > 0){
      for(let i = 0; i < payload.files.length; i++){
        const fileURL = payload.files[i];
        const fileName = fileURL.split("/").pop();
        getImage(fileName).then(file => {
          let fileData = 'data:image/jpeg;base64,' + JSON.parse(file.data);
          allFiles.push(fileData);
          if(allFiles.length === payload.files.length) {
            setUploadedFiles(allFiles);
            setIsFilesFetched(true);
          }
        });
      }
    } else {
      setIsFilesFetched(true);
    }
    
    const responseCurrencies = await currencyApi.getCurrencies();
    const payloadCurrencies = JSON.parse(responseCurrencies.data);
    const currenciesArr = payloadCurrencies.map((currency) => {
      if(currency.id === payload.currency_id){
        setCurrency(currency.id);
      }
      return {
        value: currency.id,
        label: currency.name,
      };
    });
    setCurrencies(currenciesArr);
  }

  //* Handle changes
  const handleTitleChange = e => setTitle(e.target.value);
  const handleDescriptionChange = e => setDescription(e.target.value);
  const handleAmountChange = e => setAmount(e.target.value);
  const handleCurrencyChange = e => setCurrency(e.target.value);
  const handleDateChange = e => {
    const date = dayjs(e.target.value).format("YYYY-MM-DD HH:mm");
    setDate(date);
  };
  const handleDueDateChange = e => {
    const date = dayjs(e.target.value).format("YYYY-MM-DD HH:mm");
    setDueDate(date);
  };
  const handlePayerChange = (event, newValue) => {
    if(typeof newValue === "string"){
      setPayer({
        title: newValue,
      });
    } else if(newValue && newValue.inputValue){
      setPayer({
        title: newValue.inputValue,
      });
    } else {
      setPayer(newValue);
    }
  };
  const handleUploadedFilesChange = uploaded => {
    setUploadedFiles(uploaded);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData();
    const data = {
      title: title,
      description: description,
      amount: amount,
      currency_id: currency,
      date: date,
      due_date: dueDate,
    };

    if(!description){
      data.description = "";
    }

    if(!payer) {
      data.payer = "";
    }
    if(doesExist(payer.id)){
      data.payer = payer.id + "";
    } else if (payer.title) {
      data.payer = payer.title + "";
    } else {
      data.payer = "";
    }

    Object.keys(data).forEach(key => {
      formData.append(`${key}`, data[key]);
    });

    if(uploadedFiles.length > 0) {
      uploadedFiles.forEach(file => {
        formData.append('files[]', file);
      });
    }

    const response = await loanApi.updateLoan(params.id, formData);
    const payload = JSON.parse(response.data);
    if(response.status == 200){
      showSnackbar({
        color: "success",
        message: "Loan updated successfully. Redirecting to loan's page...",
      });
      setTimeout(() => {
        historyRedirect(`/admin/loans/show/${payload.id}`);
      }, 2000);
    } else {
      showSnackbar({
        color: "danger",
        message: "Something went wrong. Please try again.",
      });
    }
  }

  return (
    <Page title={loan.title} subtitle={loan.title + " is being edited"} object="loans" id={params.id} actions={["view", "delete", "list"]}>
        <form onSubmit={handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} key="title_grid">
              <CustomInput
                labelText="Title"
                id="title"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "title",
                  onChange: handleTitleChange,
                  value: title,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} key="payer_grid">
              <CustomAutocomplete labelText="Payer/Payee" id="payer"
                options={payerOptions}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "payer",
                  value: payer,
                  onChange: handlePayerChange,
                  required: true,
                }} />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} key="desc_grid">
              <CustomInput
                labelText="Description"
                id="description"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "description",
                  onChange: handleDescriptionChange,
                  value: description,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} key="currency_grid">
              <CustomSelect
                labelText="Currency"
                id="currency"
                options={currencies}
                formControlProps={{
                  fullWidth: true,
                }}
                selectProps={{
                  name: "currency",
                  onChange: handleCurrencyChange,
                  value: currency,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} key="amount_grid">
              <CustomInput
                labelText="Amount"
                id="amount"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "amount",
                  onChange: handleAmountChange,
                  value: amount,
                  type: "number",
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} key="date_grid">
              <CustomDatetimePicker
                labelText="Date"
                id="date"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "date",
                  onChange: handleDateChange,
                  value: date,
                  type: "text",
                  required: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} key="due_date_grid">
            <CustomDatetimePicker
                labelText="Due Date"
                id="due_date"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "due_date",
                  onChange: handleDueDateChange,
                  value: dueDate,
                  type: "text",
                  required: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} key="files_grid">
              {isFilesFetched && 
                <>
                  <h3>Files</h3>
                  <CustomFile labelText="Upload files" id="files" 
                    formControlProps={{
                      fullWidth: true,
                    }}
                    uploadedFiles={uploadedFiles}
                    onChange={handleUploadedFilesChange}
                    initialFiles={uploadedFiles}
                    inputProps={{
                      id: "files",
                      name: "files",
                      required: false,
                    }} />
                </>
              }
            </GridItem>
          </GridContainer>
          <ButtonAtRight color="success" type="submit" style={{marginTop: "10px"}}>
            Update
          </ButtonAtRight>
        </form>
    </Page>
  );
}