import axios from "axios";

let headers = {
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
}

export const get = (endpoint, token=null, extraHeaders=null) => {
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    if (extraHeaders) {
        headers = {...headers, ...extraHeaders};
    }
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_SERVER + endpoint}`,
        headers: headers,
    });
}

export const post = (endpoint, data=null, token=null, extraHeaders=null) => {
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    if (extraHeaders) {
        headers = {...headers, ...extraHeaders};
    }

    const params = {
        method: "POST",
        url: `${process.env.REACT_APP_SERVER + endpoint}`,
        headers: headers,
    };

    if(data) {
        params.data = data;
    }
    
    return axios(params);
}

export const put = (endpoint, data=null, token=null, extraHeaders=null) => {
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    if (extraHeaders) {
        headers = {...headers, ...extraHeaders};
    }

    const params = {
        method: "PUT",
        url: `${process.env.REACT_APP_SERVER + endpoint}`,
        headers: headers,
    };

    if(data) {
        params.data = data;
    }

    return axios(params);
}

export const del = (endpoint, token=null, extraHeaders=null) => {
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    if (extraHeaders) {
        headers = {...headers, ...extraHeaders};
    }
    return axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_SERVER + endpoint}`,
        headers: headers,
    });
}

export const request = (options, token=null, extraHeaders=null, headers=null) => {
    headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    };
    if (token) {
        headers["Authorization"] = `Bearer ${token}`;
    }
    if (extraHeaders) {
        headers = {...headers, ...extraHeaders};
    }
    return axios({...options, headers: headers});
}

export default request;