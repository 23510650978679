import React, {useState, useEffect, useContext} from "react";

import { DialogContext, SnackbarContext } from "contexts";
// core components
import Table from "components/Table/Table.js";
import ObjectActions from "components/ObjectActions/ObjectActions.js";

import { useStyles } from "./styles";
import { useParams } from "react-router-dom";
import { historyRedirect } from "utils/helper";
import Page from "components/Page/Page";

import * as debtApi from "api/debt.api";
import CustomLink from "components/CustomLink/CustomLink";
import { number } from "utils/helper";

export default function DebtsList() {
  const classes = useStyles();
  const params = useParams();

  const [heads, setHeads] = useState([]);
  const [debts, setDebts] = useState([]);

  const {showDialog} = useContext(DialogContext);
  const {showSnackbar} = useContext(SnackbarContext);

  const availableHeads = ["title", "payer", "amount", "currency", "date", "due_date"];

  const fetchData = async () => {
    const response = await debtApi.getDebts();
    const payload = JSON.parse(response.data);
    
    if(payload.length > 0){
      const keys = Object.keys(payload[0]);
      const index = keys.indexOf("id");
      if (index > -1) {
        keys.splice(index, 1);
      }
      const filteredHeads = keys.filter((key) => availableHeads.includes(key));
      const formattedHeads = filteredHeads.map((head) => {
        return head.replace("_", " ").toUpperCase();
      });
      formattedHeads.push("ACTIONS");
      setHeads(formattedHeads);

      const values = [];
      await payload.forEach((debt) => {
        const value = [];
        keys.forEach((key) => {
          if(key === "payer") {
            let payer = debt[key];
            if(Number.isInteger(parseInt(debt[key]))) {
              payer = (
                <CustomLink href={`/admin/customers/show/${debt.payerData.id}`}>
                  {debt.payerData.name}
                </CustomLink>
              );
            }
            value.push(payer);
          } else if(key === "amount"){
            value.push(number(debt[key]) + " " + debt["currencyData"]["symbol"] + " (" + debt["currencyData"]["name"] + ")");
          } else if(key !== "id" && availableHeads.indexOf(key) != -1) {
            value.push(debt[key]);
          }
        });
        value.push(
          <div className={classes.actions}>
            <ObjectActions 
              handleDeleteClick={() => handleDeleteClick(debt.id)}
              handleViewClick={() => handleViewClick(debt.id)}
              handleEditClick={() => handleEditClick(debt.id)}
            />
          </div>
        );
        values.push(value);
      });
      setDebts(values);
    }
  };

  const handleViewClick = (id) => {
    historyRedirect(`/admin/debts/show/${id}`);
  };

  const handleEditClick = (id) => {
    historyRedirect(`/admin/debts/edit/${id}`);
  };

  const handleDelete = async (id) => {
    const response = await debtApi.deleteDebt(id);
    if (response.status == 200) {
      fetchData();
      showSnackbar({
        color: "success",
        message: "Debt has been deleted successfully",
      });
    }
  };

  const handleDeleteClick = (id) => {
    showDialog({
      title: "Delete Debt",
      message: "Are you sure you want to delete this debt?",
      onConfirm: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title="Debts" subtitle="List of all debts" object="debts" actions={["create"]}>
      {debts.length > 0 ? (
      <Table
        tableHeaderColor="success"
        tableHead={heads}
        tableData={debts}
      />
      ) : (
        <div className={classes.empty}>No debts found</div>
      )}
    </Page>
  );
}
