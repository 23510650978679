import React from "react";
import DebtCreate from "./Create";
import DebtEdit from "./Edit";
import DebtsList from "./List";
import DebtShow from "./Show";

export default function Debts(view){
    if(view === "show"){
        return <DebtShow />;
    } else if (view === "list"){
        return <DebtsList />;
    } else if (view === "create"){
        return <DebtCreate />;
    } else if (view === "edit"){
        return <DebtEdit />;
    }

    return <></>
}