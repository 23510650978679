import React from "react";
import AccountCreate from "./Create";
import AccountEdit from "./Edit";
import AccountsList from "./List";
import AccountShow from "./Show";

export default function Accounts(view){
    if(view === "show"){
        return <AccountShow />;
    } else if (view === "list"){
        return <AccountsList />;
    } else if (view === "create"){
        return <AccountCreate />;
    } else if (view === "edit"){
        return <AccountEdit />;
    }

    return <></>
}