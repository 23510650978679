import React, {useState, useEffect, useContext} from "react";
import * as accountApi from "api/account.api";

import { DialogContext, SnackbarContext } from "contexts";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import ObjectActions from "components/ObjectActions/ObjectActions.js";

import { useStyles } from "./styles";
import { Redirect, useParams } from "react-router-dom";
import history from "utils/history";
import { redirect } from "utils/helper";
import { historyRedirect } from "utils/helper";
import Page from "components/Page/Page";

export default function AccountsList() {
  const classes = useStyles();
  const params = useParams();

  const [heads, setHeads] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const {showDialog} = useContext(DialogContext);
  const {showSnackbar} = useContext(SnackbarContext);

  const availableHeads = ["name", "email", "phone"];

  const fetchData = async () => {
    const response = await accountApi.getAccounts();
    const payload = JSON.parse(response.data);

    const keys = Object.keys(payload[0]);
    const index = keys.indexOf("id");
    if (index > -1) {
      keys.splice(index, 1);
    }
    const filteredHeads = keys.filter((key) => availableHeads.includes(key));
    const formattedHeads = filteredHeads.map((head) => head.toUpperCase());
    formattedHeads.push("Balance");
    formattedHeads.push("ACTIONS");
    setHeads(formattedHeads);

    const values = [];
    await payload.forEach((account) => {
      const value = [];
      keys.forEach((key) => {
        if(key !== "id" && availableHeads.indexOf(key) != -1) {
          value.push(account[key]);
        }
      });
      value.push(account.balance + " " + account.currency.symbol);
      value.push(
        <div className={classes.actions}>
          <ObjectActions 
            handleDeleteClick={() => handleDeleteClick(account.id)}
            handleViewClick={() => handleViewClick(account.id)}
            handleEditClick={() => handleEditClick(account.id)}
            handleAddActionClick={() => handleAddActionClick(account.id)}
            addAction={true}
          />
        </div>
      );
      values.push(value);
    });
    setAccounts(values);
  };

  const handleViewClick = (id) => {
    historyRedirect(`/admin/accounts/show/${id}`);
  };

  const handleEditClick = (id) => {
    historyRedirect(`/admin/accounts/edit/${id}`);
  };

  const handleDelete = async (id) => {
    const response = await accountApi.deleteAccount(id);
    if (response.status == 200) {
      fetchData();
      showSnackbar({
        color: "success",
        message: "Account has been deleted successfully",
      });
    }
  };

  const handleDeleteClick = (id) => {
    showDialog({
      title: "Delete Account",
      message: "Are you sure you want to delete this account?",
      onConfirm: () => handleDelete(id),
    });
  };

  const handleAddActionClick = (accountId) => {
    historyRedirect('/admin/actions/create/' + accountId);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page title="Accounts" subtitle="List of all accounts" object="accounts" actions={["create"]}>
      <Table
        tableHeaderColor="success"
        tableHead={heads}
        tableData={accounts}
      />
    </Page>
  );
}
