import React, { useState, createContext, useEffect } from 'react';
import Snackbar from 'components/Snackbar/Snackbar';

const SnackbarContext = createContext();

const SnackbarProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState('info');
    const [message, setMessage] = useState('');

    const showSnackbar = (options) => {
        if(options.color) setColor(options.color);
        if(options.message) setMessage(options.message);
        setOpen(true);
    }

    const closeSnackbar = () => {
        setOpen(false);
    }

    useEffect(() => {
        if(open) setTimeout(() => closeSnackbar(), 3000);
    }, [open]);

    return (
        <SnackbarContext.Provider value={{ showSnackbar, closeSnackbar }}>
            {children}
            <Snackbar open={open} color={color} message={message} place="tr" close closeNotification={() => setOpen(false)} />
        </SnackbarContext.Provider>
    );
};

export { SnackbarContext, SnackbarProvider };
