import React, { useState, useEffect } from "react";
import * as settingApi from "api/setting.api";
import * as currencyApi from "api/currency.api";
// core components
import { SnackbarContext } from "contexts/SnackbarContext";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import useStyles from "./styles";
import { useContext } from "react";
import { isAllResponsesSuccess } from "utils/helper";
import CustomSelect from "components/CustomSelect/CustomSelect";
const _ = require("lodash");

export default function Settings() {
  const classes = useStyles();
  
  const [expenseAlert, setExpenseAlert] = useState(0);
  const [incomeAlert, setIncomeAlert] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [defaultCurrency, setDefaultCurrency] = useState(1);

  const handleExpenseAlertChange = (e) => setExpenseAlert(e.target.value);
  const handleIncomeAlertChange = (e) => setIncomeAlert(e.target.value);
  const handleDefaultCurrencyChange = (e) => setDefaultCurrency(e.target.value);
  
  const fetchData = async () => {
    const responseExpenseAlert = await settingApi.getSetting('expense_alert');
    const payloadExpenseAlert = JSON.parse(responseExpenseAlert.data);
    setExpenseAlert(payloadExpenseAlert.value);

    const responseIncomeAlert = await settingApi.getSetting('income_alert');
    const payloadIncomeAlert = JSON.parse(responseIncomeAlert.data);
    setIncomeAlert(payloadIncomeAlert.value);

    const responseDefaultCurrency = await currencyApi.getCurrencies();
    const payloadDefaultCurrency = JSON.parse(responseDefaultCurrency.data);
    const currenciesArr = payloadDefaultCurrency.map((currency) => {
      return {
        value: currency.id,
        label: currency.name,
      };
    });
    setCurrencies(currenciesArr);
    const defaultCurrencyItem = _.find(payloadDefaultCurrency, function(item){
      if(item.is_default){
        return true;
      }
    });
    setDefaultCurrency(defaultCurrencyItem.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { showSnackbar } = useContext(SnackbarContext);

  const handleOnUpdate = async (e) => {
    e.preventDefault();
    const responses = [];

    const responseExpenseAlert = await settingApi.updateSetting('expense_alert', expenseAlert);
    responses.push(responseExpenseAlert);
    const responseIncomeAlert = await settingApi.updateSetting('income_alert', incomeAlert);
    responses.push(responseIncomeAlert);
    const responseDefaultCurrency = await currencyApi.setDefaultCurrency(defaultCurrency);
    responses.push(responseDefaultCurrency);
    
    
    if (isAllResponsesSuccess(responses)) {
      showSnackbar({
        color: "success",
        message: "Settings updated successfully",
      });
    } else {
      showSnackbar({
        color: "danger",
        message: "Failed to update settings",
      });
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <form onSubmit={handleOnUpdate}>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Settings</h4>
              <p className={classes.cardCategoryWhite}>Set your settings</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Expense Alert Value"
                    id="expense_alert"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: expenseAlert,
                      onChange: handleExpenseAlertChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Income Alert Value"
                    id="income_alert"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: incomeAlert,
                      onChange: handleIncomeAlertChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomSelect
                    labelText="Default Currency"
                    id="default_currency"
                    formControlProps={{
                      fullWidth: true
                    }}
                    options={currencies}
                    selectProps={{
                      name: "default_currency",
                      value: defaultCurrency,
                      onChange: handleDefaultCurrencyChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              
            </CardBody>
            <CardFooter>
              <Button color="primary" type="submit">Update Settings</Button>
            </CardFooter>
          </form>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
