import React from 'react';
import { ArrowDownward, ArrowUpward, ArrowForward } from "@material-ui/icons";

export function Expense(props) {
	const {
		value,
    secondValue,
	} = props;

	return (
    <span style={{color: "red"}}>
      {value}
      <ArrowUpward />
    </span>
  );
}

export function Income(props) {
  const {
    value,
  } = props;

  return (
    <span style={{color: "green"}}>
      {value}
      <ArrowDownward />
    </span>
  );
}

export function CurrencyExchange(props) {
  const {
    value,
    secondValue,
  } = props;

  return (
    <span style={{color: "orange"}}>
      {value} <br />
      <ArrowForward /> <br />
      ({secondValue})
    </span>
  );
}

export function ActionValue(props) {
  const {
    value,
    secondValue,
  } = props;

  if(props.type === "currency_exchange"){
    return <CurrencyExchange value={value} secondValue={secondValue} />;
  } else if(value < 0){
    return <Expense value={value} />
  } else {
    return <Income value={value} />
  }
}
