import React from "react";
import { Route } from "react-router-dom";

export default function RouteView(prop) {
    const possibleViews = prop.possibleViews;
    return (
        <>
            {possibleViews.includes("list") ? (
                <Route
                    path={prop.path + "/list"}
                    component={() => prop.component("list")}
                    key={prop.key + "-list"}
                />
            ): null}
            {possibleViews.includes("create") ? (
            <Route
                path={prop.path + "/create/:account?"}
                component={() => prop.component("create")}
                key={prop.key + "-create"}
            />
            ): null}
            {possibleViews.includes("edit") ? (
                <Route
                    path={prop.path + "/edit/:id"}
                    component={() => prop.component("edit")}
                    key={prop.key + "-edit"}
                />
            ): null}
            {possibleViews.includes("show") ? (
                <Route
                    path={prop.path + "/show/:id"}
                    component={() => prop.component("show")}
                    key={prop.key + "-show"}
                />
            ): null}
        </>
    );
}