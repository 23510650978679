import React from "react";
import { SnackbarProvider, SnackbarContext } from "./SnackbarContext";
import { DialogContext, DialogProvider } from "./DialogContext";

const AppProviders = ({ children }) => {
    return (
        <SnackbarProvider>
            <DialogProvider>
                {children}
            </DialogProvider>
        </SnackbarProvider>
    );
};

export { 
    AppProviders,
    SnackbarContext, 
    SnackbarProvider, 
    DialogContext, 
    DialogProvider,
};