import { request, get, post } from '../utils/request';

export const getLoans = async () => {
    return get('/loan');
};

export const getLoan = async (id) => {
    return get(`/loan/${id}`);
}

export const updateLoan = async (id, data) => {
    return post(`/loan/${id}?_method=PUT`, data);
}

export const createLoan = async (data) => {
    return post('/loan', data);
}

export const deleteLoan = async (id) => {
    return request({
        url: `/loan/${id}`,
        method: 'delete',
    });
}
